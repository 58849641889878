// packages
import Tooltip from "@mui/material/Tooltip";
import "animate.css/animate.min.css";
import { ethers } from "ethers";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

// pages and components
import celestialIcon from "../../assets/celestial1.png";
import copyIcon from "../../assets/copyIcon.svg";
import machinaIcon from "../../assets/machina1.png";
import metamaskIcon from "../../assets/metamaskIcon.png";
// import networkOff from "../../assets/networkOff.svg";
// import noMetamask from "../../assets/noMetamask.svg";
import proxIcon from "../../assets/proxIcon.png";
import tellurianIcon from "../../assets/tellurian1.png";

// styles
import "./Contracts.css";

const Contracts = ({ currentMenu }) => {
  const [openCopyTooltip, setOpenCopyTooltip] = useState(null);
  const [openTokenTooltip, setOpenTokenTooltip] = useState(null);

  // eslint-disable-next-line
  const [haveMetaMask, setHaveMetaMask] = useState(true);
  const [chainID, setChainID] = useState("");
  const mtvID = 62621;
  const { singleResource } = useParams();
  const contactData = [
    {
      icon: proxIcon,
      params: {
        address: "0x9029d1dEDa00e166De55406eAB2608E4efC66D7b",
        symbol: "PROX",
        decimals: 18,
      },
    },
    {
      icon: tellurianIcon,
      params: {
        address: "0x70533ad4fad157351294ee906cc2f09c922d213a",
        symbol: "Tellurian",
        decimals: 0,
      },
    },
    {
      icon: machinaIcon,
      params: {
        address: "0xd8b9c28a7275107cc1b1bc80e578b4d888386a38",
        symbol: "Machina",
        decimals: 0,
      },
    },
    {
      icon: celestialIcon,
      params: {
        address: "0xd97333f247b3d343f8886320beed80bf6cca9d77",
        symbol: "Celestial",
        decimals: 0,
      },
    },
  ];

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [singleResource]);

  if (haveMetaMask) {
    const { ethereum } = window;

    // side function to fetch and set chainID
    const getChainID = async () => {
      const id = await ethereum.request({ method: "eth_chainId" });
      setChainID(parseInt(id, 16));
    };
    getChainID();

    // side function to switch chain
    // eslint-disable-next-line
    const switchChainHandler = async () => {
      if (ethereum.networkVersion !== mtvID) {
        try {
          await ethereum.request({
            method: "wallet_switchEthereumChain",
            params: [{ chainId: ethers.utils.hexlify(mtvID) }],
          });
        } catch (err) {
          // This error code indicates that the chain has not been added to MetaMask
          if (err.code === 4902) {
            await ethereum.request({
              method: "wallet_addEthereumChain",
              params: [
                {
                  chainName: "MultiVAC Mainnet",
                  chainId: ethers.utils.hexlify(mtvID),
                  nativeCurrency: { name: "MTV", decimals: 18, symbol: "MTV" },
                  rpcUrls: ["https://rpc.mtv.ac"],
                },
              ],
            });
          }
        }
      }
    };

    // side function for tooltip
    const toggleClick = (index, type) => {
      if (type === "copy") {
        setOpenCopyTooltip(openCopyTooltip === index ? null : index);
        setTimeout(() => {
          setOpenCopyTooltip(null);
        }, "1000");
      }
      if (type === "token") {
        setOpenTokenTooltip(openTokenTooltip === index ? null : index);
        setTimeout(() => {
          setOpenTokenTooltip(null);
        }, "1000");
      }
    };

    // side function to add to clipboard
    const copyToClipboard = async (address) => {
      await navigator.clipboard.writeText(address);
    };

    // side function to add token
    const addToken = async (tokenData, index) => {
      const tokenContract = tokenData.address;
      const tokenSymbol = tokenData.symbol;
      const tokenDecimals = tokenData.decimals;

      try {
        const wasAdded = await ethereum.request({
          method: "wallet_watchAsset",
          params: {
            type: "ERC20",
            options: {
              address: tokenContract,
              symbol: tokenSymbol,
              decimals: tokenDecimals,
            },
          },
        });
        if (wasAdded) {
          toggleClick(index, "token");
        } else {
          console.log("Not Added");
        }
      } catch (error) {
        console.log(error);
      }
    };

    // Return template

    if (chainID === mtvID) {
      return (
        <>
          <div className="singleMenuPage">
            <div className="singleMenuPageContainer">
              {/* Left container */}
              <div className="singleMenuPage-currentSubMenu">
                <h3 className="currentSubMenu-title">{currentMenu.title}</h3>
                <img
                  src={currentMenu.icon}
                  alt="icon"
                  className="currentSubMenu-icon"
                />
              </div>

              {/* Right container */}
              <div className="singleMenuPage-actionsContainer">
                {contactData.map((contract, index) => (
                  <div
                    key={index}
                    className="actionsContainer-action-contracts"
                  >
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        width: "100%",
                        gap: "1em",
                        margin: "1em",
                      }}
                    >
                      <img
                        className="singleContractIcon"
                        src={contract.icon}
                        alt="Icon"
                        style={{ width: "4em", aspectRatio: "1" }}
                      />
                      <div className="singleContractData">
                        <div className="contractName">
                          {contract.params.symbol} Token:
                        </div>
                        <div className="contractContainer">
                          <div className="contract">
                            {contract.params.address.slice(0, 5) +
                              "..." +
                              contract.params.address.slice(38, 42)}
                          </div>
                          <div className="iconContainer">
                            <Tooltip
                              onClose={() => toggleClick(null)}
                              open={openCopyTooltip === index}
                              title="Copied to clipboard"
                              arrow
                              PopperProps={{
                                disablePortal: true,
                              }}
                              disableFocusListener
                              disableHoverListener
                              disableTouchListener
                            >
                              <div className="surroundIcon">
                                <img
                                  style={{ width: "1.4em", aspectRatio: "1" }}
                                  className="addToClipboard"
                                  src={copyIcon}
                                  alt="clipboardCopy"
                                  onClick={() => {
                                    toggleClick(index, "copy");
                                    copyToClipboard(contract.params.address);
                                  }}
                                />
                              </div>
                            </Tooltip>
                            <Tooltip
                              onClose={() => toggleClick(null)}
                              open={openTokenTooltip === index}
                              title="Token added"
                              arrow
                              PopperProps={{
                                disablePortal: true,
                              }}
                              disableFocusListener
                              disableHoverListener
                              disableTouchListener
                            >
                              <img
                                style={{ width: "1.6em", aspectRatio: "1" }}
                                className="surroundIcon"
                                src={metamaskIcon}
                                alt="addToMetamask"
                                onClick={() => addToken(contract.params, index)}
                              />
                            </Tooltip>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </>
      );
    }

    if (chainID !== mtvID) {
      return (
        <>
          <div className="singleMenuPage">
            <div className="singleMenuPageContainer">
              {/* Left container */}
              <div className="singleMenuPage-currentSubMenu">
                <h3 className="currentSubMenu-title">{currentMenu.title}</h3>
                <img
                  src={currentMenu.icon}
                  alt="icon"
                  className="currentSubMenu-icon"
                />
              </div>

              {/* Right container */}
              <div className="singleMenuPage-actionsContainer">
                {contactData.map((contract, index) => (
                  <div
                    key={index}
                    className="actionsContainer-action"
                    style={{}}
                  >
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        width: "100%",
                        gap: "1em",
                        margin: "1em",
                      }}
                    >
                      <img
                        className="singleContractIcon"
                        src={contract.icon}
                        alt="Icon"
                        style={{ width: "4em", aspectRatio: "1" }}
                      />
                      <div className="singleContractData">
                        <div className="contractName">
                          {contract.params.symbol} Token:
                        </div>
                        <div className="contractContainer">
                          <div className="contract">
                            {contract.params.address.slice(0, 5) +
                              "..." +
                              contract.params.address.slice(38, 42)}
                          </div>
                          <div className="iconContainer">
                            <Tooltip
                              onClose={() => toggleClick(null)}
                              open={openCopyTooltip === index}
                              title="Copied to clipboard"
                              arrow
                              PopperProps={{
                                disablePortal: true,
                              }}
                              disableFocusListener
                              disableHoverListener
                              disableTouchListener
                            >
                              <div className="surroundIcon">
                                <img
                                  style={{ width: "1.4em", aspectRatio: "1" }}
                                  className="addToClipboard"
                                  src={copyIcon}
                                  alt="clipboardCopy"
                                  onClick={() => {
                                    toggleClick(index, "copy");
                                    copyToClipboard(contract.params.address);
                                  }}
                                />
                              </div>
                            </Tooltip>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </>
      );
    }
  } else {
    // side function for tooltip
    const toggleClick = (index, type) => {
      if (type === "copy") {
        setOpenCopyTooltip(openCopyTooltip === index ? null : index);
        setTimeout(() => {
          setOpenCopyTooltip(null);
        }, "1000");
      }
      if (type === "token") {
        setOpenTokenTooltip(openTokenTooltip === index ? null : index);
        setTimeout(() => {
          setOpenTokenTooltip(null);
        }, "1000");
      }
    };

    // side function to add to clipboard
    const copyToClipboard = async (address) => {
      navigator.clipboard.writeText(address);
    };

    return (
      <>
        <div className="singleMenuPage">
          <div className="singleMenuPageContainer">
            {/* Left container */}
            <div className="singleMenuPage-currentSubMenu">
              <h3 className="currentSubMenu-title">{currentMenu.title}</h3>
              <img
                src={currentMenu.icon}
                alt="icon"
                className="currentSubMenu-icon"
              />
            </div>

            {/* Right container */}
            <div className="singleMenuPage-actionsContainer">
              {contactData.map((contract, index) => (
                <div key={index} className="actionsContainer-action" style={{}}>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      width: "100%",
                      gap: "1em",
                      margin: "1em",
                    }}
                  >
                    <img
                      className="singleContractIcon"
                      src={contract.icon}
                      alt="Icon"
                      style={{ width: "4em", aspectRatio: "1" }}
                    />
                    <div className="singleContractData">
                      <div className="contractName">
                        {contract.params.symbol} Token:
                      </div>
                      <div className="contractContainer">
                        <div className="contract">
                          {contract.params.address.slice(0, 5) +
                            "..." +
                            contract.params.address.slice(38, 42)}
                        </div>
                        <div className="iconContainer">
                          <Tooltip
                            onClose={() => toggleClick(null)}
                            open={openCopyTooltip === index}
                            title="Copied to clipboard"
                            arrow
                            PopperProps={{
                              disablePortal: true,
                            }}
                            disableFocusListener
                            disableHoverListener
                            disableTouchListener
                          >
                            <div className="surroundIcon">
                              <img
                                style={{ width: "1.4em", aspectRatio: "1" }}
                                className="addToClipboard"
                                src={copyIcon}
                                alt="clipboardCopy"
                                onClick={() => {
                                  toggleClick(index, "copy");
                                  copyToClipboard(contract.params.address);
                                }}
                              />
                            </div>
                          </Tooltip>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </>
    );
  }
};

export default Contracts;
