// packages
import React, { useEffect } from "react";
import { useParams } from "react-router-dom";

// pages and components
import { extractContent } from "../../components/functions/extractContent";
import SubMenu from "../../components/SubMenu";
import { menuItemData } from "../../data/menuItemData";

// styles
import "./SingleShop.css";

const SingleShop = () => {
  const { singleShop } = useParams();

  const [currentMenu] = extractContent(menuItemData, 1, "Shop").filter(
    (content) => content.search === singleShop
  );

  const contents = extractContent(
    menuItemData,
    2,
    "Shop",
    singleShop,
    "special"
  );

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, [singleShop]);

  if (singleShop === "proxtoken") {
    return (
      <>
        <SubMenu
          subMenuTitle={currentMenu.title}
          subMenuIcon={currentMenu.icon}
          contents={contents}
        />
      </>
    );
  }

  return (
    <SubMenu
      subMenuTitle={currentMenu.title}
      subMenuIcon={currentMenu.icon}
      contents={contents}
    />
  );
};

export default SingleShop;
