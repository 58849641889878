// packages
import React from "react";

// pages and components
import ClosingSection from "./ClosingSection";
import CommunitySection from "./CommunitySection";
import LinkSection from "./LinkSection";

// styles

const Footer = () => {
  return (
    <>
      <CommunitySection />
      <LinkSection />
      <ClosingSection />
    </>
  );
};

export default Footer;
