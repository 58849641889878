import React, { useEffect, useRef, useState } from "react";
import { NavLink } from "react-router-dom";
import Dropdown from "./Dropdown";
import "./MenuItems.css";

const MenuItems = ({ items, depthLevel, menuOpen }) => {
  const [dropDown, setDropDown] = useState(false);
  const ref = useRef();

  //   Close when outside click https://blog.logrocket.com/detect-click-outside-react-component-how-to/
  useEffect(() => {
    const handler = (event) => {
      if (dropDown && ref.current && !ref.current.contains(event.target)) {
        setDropDown(false);
      }
    };
    document.addEventListener("mousedown", handler);
    document.addEventListener("touchstart", handler);
    return () => {
      document.removeEventListener("mousedown", handler);
      document.removeEventListener("touchstart", handler);
    };
  }, [dropDown]);
  //

  // Hover for non-mobile
  const onMouseEnter = () => {
    window.innerWidth > 960 && setDropDown(true);
  };
  const onMouseLeave = () => {
    window.innerWidth > 960 && setDropDown(false);
  };
  //

  return (
    <li
      className="menu-items"
      ref={ref}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      {items.subMenu ? (
        menuOpen ? (
          <>
            <p
              onClick={() => {
                setDropDown((prev) => !prev);
              }}
            >
              {items.title}{" "}
              {depthLevel > 0 ? (
                <span>&raquo;</span>
              ) : (
                <span className="arrow" />
              )}
            </p>
            <Dropdown
              subMenu={items.subMenu}
              dropDown={dropDown}
              depthLevel={depthLevel}
            />
          </>
        ) : (
          <>
            <NavLink
              id="mil"
              to={`${items.navLink}`}
              onClick={() => {
                setDropDown((prev) => !prev);
              }}
            >
              {items.title}{" "}
              {depthLevel > 0 ? (
                <span>&raquo;</span>
              ) : (
                <span className="arrow" />
              )}
            </NavLink>
            <Dropdown
              subMenu={items.subMenu}
              dropDown={dropDown}
              depthLevel={depthLevel}
            />
          </>
        )
      ) : items.type !== "externalURL" ? (
                  <NavLink to={`${items.navLink}`}>{items.title}</NavLink>

      ) : (
        <a href={items.src}>{items.title}</a>
      )}
    </li>
  );
};

export default MenuItems;
