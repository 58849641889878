import celestial1 from "../assets/celestial1.png";
import chartJSIcon from "../assets/chartJSIcon.svg";
import mtvIcon from "../assets/mtvIcon.png";
import epicIcon from "../assets/epicIcon.png";
import gameIconSAP from "../assets/gameIconSAP.png";
import guruIcon from "../assets/guruIcon.png";
import machina1 from "../assets/machina1.png";
import nftIcon from "../assets/nftIcon.png";
import proxIcon from "../assets/proxIcon.png";
import roadMapSAP from "../assets/roadMapSAP.png";
// import starmapIcon from "../assets/starmapIcon.png";
import teamSAP from "../assets/teamSAP.png";
import tellurian1 from "../assets/tellurian1.png";
import vaxIcon from "../assets/vaxIcon.svg";
// import whitepaperIcon from "../assets/whitepaperIcon.png";
import contractIconSAP from "../assets/contractIconSAP.png";
import gettingStartedSAP from "../assets/gettingStartedSAP.png";
// import headLogo from "../assets/headLogo.png";
// import metamaskIcon from "../assets/metamaskIcon.png";
// import mtvIcon from "../assets/mtvIcon.png";
import whitepaperSAP from "../assets/whitepaperSAP.png";


export const menuItemData = [
    {
        title: "Game",
        src: "game",
        navLink: "game/gettingstarted",
        subMenu: [
            {
                title: "Play Game",
                search: "play game",
                navLink: "/game/gameone", // This should match the path in your router
                src: "",
                icon: gameIconSAP,
                type: "page", // Indicates an internal route
                desktopRelevant: "yes",
            },
            {
                title: "Getting started",
                search: "gettingstarted",
                navLink: "game/gettingstarted",
                src: "gettingstarted",
                icon: gettingStartedSAP,
                type: "page",
            },
        ],
    },
  {
    title: "Factions",
    src: "factions",
    navLink: "factions",
    subMenu: [
      {
        title: "Tellurian",
        search: "tellurian",
        src: "tellurian",
        navLink: "factions/tellurian",
        ship: tellurian1,
        icon: tellurian1,
        subMenu: [
          {
            title: "Traits and Rarities",
            icon: chartJSIcon,
            src: "traits-and-rarities",
            navLink: "factions/tellurian/traits-and-rarities",
            type: "page",
          },
          {
            title: "Shop Tellurian NFT's",
            icon: epicIcon,
            src: "https://epic.gallery/collections/Proxima%20Tellurian%20Faction",
            type: "externalURL",
          },
        ],
      },
      {
        title: "Machina",
        search: "machina",
        src: "machina",
        navLink: "factions/machina",
        ship: machina1,
        icon: machina1,
        subMenu: [
          {
            title: "Traits and Rarities",
            icon: chartJSIcon,
            src: "traits-and-rarities",
            navLink: "factions/machina/traits-and-rarities",
            type: "page",
          },
          {
            title: "Shop Machina NFT's",
            icon: epicIcon,
            src: "https://epic.gallery/collections/Proxima%20Machina%20Faction",
            type: "externalURL",
          },
        ],
      },
      {
        title: "Celestial",
        search: "celestial",
        src: "celestial",
        navLink: "factions/celestial",
        ship: celestial1,
        icon: celestial1,
        subMenu: [
          {
            title: "Traits and Rarities",
            icon: chartJSIcon,
            src: "traits-and-rarities",
            navLink: "factions/celestial/traits-and-rarities",
            type: "page",
          },
          {
            title: "Shop Celestial NFT's",
            icon: epicIcon,
            src: "https://epic.gallery/collections/Proxima%20Celestial%20Faction",
            type: "externalURL",
          },
        ],
      },
    ],
  },
    {
        title: "Resources",
        src: "resources",
        navLink: "resources",
        subMenu: [
            {
                title: "White Paper",
                search: "whitepaper",
                navLink: "resources/whitepaper",
                src: "whitepaper",
                icon: whitepaperSAP,
            },
            {
                title: "Starmap",
                search: "starmap",
                navLink: "resources/starmap",
                src: "starmap",
                icon: roadMapSAP,
            },
            {
                title: "Team",
                search: "team",
                navLink: "resources/team",
                src: "team",
                icon: teamSAP,
            },
            {
                title: "Circulating Supply",
                search: "supply",
                navLink: "resources/supply",
                src: "supply",
                icon: contractIconSAP,
                type: "page",
            },
            {
                title: "Contract addresses",
                search: "contracts",
                navLink: "resources/contracts",
                src: "contracts",
                icon: contractIconSAP,
            },
            {
                title: "Search NFT",
                search: "searchNFT",
                navLink: "resources/searchNFT",
                src: "searchNFT",
                icon: contractIconSAP,
                type: "page",
            }
        ],
  },
  {
    title: "Shop",
    src: "shop",
    navLink: "shop",
    subMenu: [
      {
        title: "$PROX Token",
        search: "proxtoken",
        navLink: "shop/proxtoken",
        src: "proxtoken",
        icon: proxIcon,
        subMenu: [
          {
            title: "Buy/Sell",
            src: "https://vax.mtv.guru/#/swap",
            icon: vaxIcon,
            type: "externalURL",
          },
          {
            title: "Open staking (5% APY)",
            src: "https://mtv.guru/LF/611",
            icon: guruIcon,
            type: "externalURL",
          },
          {
            title: "Locked staking (12.5% APY)",
            src: "https://mtv.guru/LF/610",
            icon: guruIcon,
            type: "externalURL",
          },
          {
            title: "Locked staking (17.5% APY)",
            src: "https://mtv.guru/LF/614",
            icon: guruIcon,
            type: "externalURL",
          },
          {
            title: "Yield Farm USDC/PROX",
            src: "https://mtv.guru/LF/612",
            icon: guruIcon,
            type: "externalURL",
          },
          {
            title: "Yield Farm MTV/PROX",
            src: "https://mtv.guru/LF/613",
            icon: guruIcon,
            type: "externalURL",
          },
        ],
      },
      {
        title: "NFT Marketplace",
        search: "marketplace",
        navLink: "shop/marketplace",
        src: "marketplace",
        icon: nftIcon,
          subMenu: [
              {
                  title: "MultiVAC Official Marketplace",
                  icon: mtvIcon,
                  src: "https://n.mtv.ac/",
                  type: "externalURL",
              },
          {
            title: "Epic Gallery",
            icon: epicIcon,
            src: "https://epic.gallery/",
            type: "externalURL",
            },
        ],
      },
      // { title: "Merchandise", src: "shop/merchandise" },
    ],
  },
];
