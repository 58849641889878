// packages
import React, { useState } from "react";

// pages and components
import { addDoc, collection } from "firebase/firestore";
import discord from "../../assets/discord.svg";
import medium from "../../assets/medium.svg";
import reddit from "../../assets/reddit.svg";
import telegram from "../../assets/telegram.svg";
import twitter from "../../assets/twitter.svg";
import { db } from "../firebaseConfig/config";

// styles
import "./CommunitySection.css";

const CommunitySection = () => {
  const [fieldInput, setFieldInput] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (fieldInput) {
      await addDoc(collection(db, "email"), { address: fieldInput });

      setFieldInput("");
    }
  };

  const handleInput = (e) => {
    setFieldInput(e.target.value);
  };

  return (
    <div className="community-container">
      <div className="sign-up">
        <div className="sign-up-text-box">
          <h1 className="sign-up-title">Sign up!</h1>
          <p className="sign-up-text">
            Join our mailing list to stay in the loop with the latest news!
          </p>
        </div>
        <form className="sign-up-container" onSubmit={handleSubmit}>
          <input
            className="sign-up-input"
            type="email"
            placeholder="Enter your E-Mail"
            onChange={handleInput}
            value={fieldInput}
          ></input>
          <button className="sign-up-button" type="submit">
            Sign up
          </button>
        </form>
      </div>
      <div className="socials">
        <h1 className="socials-title">Join our community</h1>
        <div className="socials-container">
          <a href="https://twitter.com/ProximaTheGame">
            <img className="twitter-logo" src={twitter} alt="twitter" />
          </a>
          <a href="https://discord.gg/WszeDhcTcN">
            <img className="discord-logo" src={discord} alt="discord" />
          </a>
          <a href="https://t.me/ProximaTheGame">
            <img className="telegram-logo" src={telegram} alt="telegram" />
          </a>
          <a href="https://medium.com/@ProximaTheGame">
            <img className="medium-logo" src={medium} alt="medium" />
          </a>
          <a href="https://www.reddit.com/r/Proxima_The_Game/">
            <img className="reddit-logo" src={reddit} alt="reddit" />
          </a>
        </div>
      </div>
    </div>
  );
};

export default CommunitySection;
