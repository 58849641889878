import React from "react";
import "./Dropdown.css";
import MenuItems from "./MenuItems";

const Dropdown = ({ subMenu, dropDown, depthLevel }) => {
  depthLevel = depthLevel + 1;
  const dropDownClass = depthLevel > 1 ? "dropdown-submenu" : "";

  return (
    <ul className={`dropdown ${dropDownClass} ${dropDown ? "show" : ""}`}>
      {subMenu.map((subMenu, index) => {
        return (
          <MenuItems items={subMenu} key={index} depthLevel={depthLevel} />
        );
      })}
    </ul>
  );
};

export default Dropdown;
