// packages

// pages and components
import celestialIcon from "../../../assets/celestial1.png";
import machinaIcon from "../../../assets/machina1.png";
import proxIcon from "../../../assets/proxIcon.png";
import tellurianIcon from "../../../assets/tellurian1.png";

// styles
import "./StepFour.css";

const StepFour = () => {
  return (
    <div className="stepContentContainer">
      <div className="leftContentSection">
        <div className="reqNotFul extentions">
          <div className="browserExtentionContainer">
            <div style={{ textAlign: "center", marginTop: "0.5em" }}>
              Shop NFTS
            </div>
            <div className="extentionDivider"></div>
            <a
              href="https://epic.gallery/collections/Proxima%20Tellurian%20Faction"
              className="browserExtention"
            >
              <img
                src={tellurianIcon}
                alt="tellurian"
                className="singleConnectionIcon"
              />
              <div className="chromeName">Tellurian Faction</div>
            </a>
            <a
              href="https://epic.gallery/collections/Proxima%20Machina%20Faction"
              className="browserExtention"
            >
              <img
                src={machinaIcon}
                alt="machina"
                className="singleConnectionIcon"
              />
              <div className="chromeName">Machina Faction</div>
            </a>
            <a
              href="https://epic.gallery/collections/Proxima%20Celestial%20Faction"
              className="browserExtention"
            >
              <img
                src={celestialIcon}
                alt="celestial"
                className="singleConnectionIcon"
              />
              <div className="chromeName">Celestial Faction</div>
            </a>
          </div>
          <div className="mobileAppContainer">
            <div style={{ textAlign: "center", marginTop: "0.5em" }}>
              In-Game Currency
            </div>
            <div className="extentionDivider"></div>
            <a href="https://vax.mtv.guru/#/swap" className="browserExtention">
              <img src={proxIcon} alt="prox" className="singleConnectionIcon" />
              <div className="chromeName">$PROX Token</div>
            </a>
          </div>
        </div>
      </div>
      <div className="rightContentSection">
        <div className="reqNotFul extentions">
          <div className="browserExtentionContainer">
            <div style={{ textAlign: "center", marginTop: "0.5em" }}>
              Go shopping!
            </div>
            <div className="extentionDivider"></div>
            <div>
              <span style={{ color: "var(--blueColor)" }}>Usage of NFTs</span>
              <br />
              Proxima can be enjoyed completely for free. You will be provided
              with a basic ship and can start right away to conquer space. If
              you want to get an edge over your enemies you can visit EPIC
              Gallery to buy a ship from the secondary market.
            </div>
            <div>
              <span style={{ color: "var(--blueColor)" }}>Usage of $PROX</span>
              <br />
              If you have not settled on a specific ship yet and want to test
              out different ones by renting via the in-game store or want to buy
              in-game items and need $PROX, VEX got you covered.
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StepFour;
