// packages
// eslint-disable-next-line
import Chart from "chart.js/auto";
import React, { useEffect, useState } from "react";
import { Bar } from "react-chartjs-2";

// pages and components

const BarChart = ({ chartData, title }) => {
  const [titleFontSize, setTitleFontSize] = useState(0);
  const [windowSize, setWindowSize] = useState(window.innerWidth);

  Chart.defaults.font.family = "Kanit";
  Chart.defaults.color = "#f5f5f5";

  // get window size
  useEffect(() => {
    const handleResize = () => {
      setWindowSize(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, [windowSize]);

  // get chart options
  useEffect(() => {
    if (windowSize < 391) {
      Chart.defaults.font.size = 9;
      setTitleFontSize(14);
    }
    // if (windowSize > 900) {
    //   Chart.defaults.font.size = 14;
    //   setTitleFontSize(22);
    // }

    if (windowSize > 391) {
      Chart.defaults.font.size = 14;
      setTitleFontSize(22);
    }
  }, [windowSize]);

  // set chart options
  const options = {
    responsive: true,
    maintainAspectRatio: false,
    indexAxis: "x",
    scales: {
      y: {
        ticks: {
          color: "#f5f5f5",
        },
      },
      x: {
        ticks: {
          color: "#f5f5f5",
        },
      },
    },
    plugins: {
      title: {
        display: true,
        text: title,
        font: {
          size: titleFontSize,
          family: "Silkscreen",
          weight: "bold",
        },
      },
    },
  };

  return <Bar data={chartData} options={options} />;
};

export default BarChart;

// const options = {
//   indexAxis: "y",
//   responsive: true,
//   plugins: {
//     legend: {
//       position: "right",
//     },
//     title: {
//       display: true,
//       text: title,
//       color: "#f5f5f5",
//     },
//   },
// };
