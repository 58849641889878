export const extractTraits = (
  traitData,
  depth,
  mainTraitTerm,
  subTraitTerm
) => {
  let result = [];

  traitData.forEach((traitData) => {
    if (depth === 0) {
      result = [...result, traitData];
    }

    if (depth === 1) {
      if (traitData.faction === mainTraitTerm) {
        traitData.traits.forEach((trait) => {
          result = [...result, trait];
        });
      }
    }

    if (depth === 2) {
      if (traitData.faction === mainTraitTerm) {
        traitData.traits.forEach((trait) => {
          if (trait.trait === subTraitTerm) {
            trait.subTrait.forEach((subTrait) => {
              result = [...result, subTrait];
            });
          }
        });
      }
    }
  });

  //   function return parameter
  return result;
};
