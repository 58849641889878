// packages
import "animate.css/animate.min.css";
import React, { useEffect } from "react";
import { useParams } from "react-router-dom";

// pages and components

// styles
import "./Team.css";

const Team = ({ currentMenu }) => {
  const { singleResource } = useParams();

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, [singleResource]);

  // Return template

  return (
    <>
      <div className="singleMenuPage">
        <div className="singleMenuPageContainer">
          {/* left container */}
          <div className="singleMenuPage-currentSubMenu">
            <h3 className="currentSubMenu-title">{currentMenu.title}</h3>
            <img
              src={currentMenu.icon}
              alt="icon"
              className="currentSubMenu-icon"
            />
          </div>

          {/* right container */}
          <div className="singleMenuPage-actionsContainer">
            <div
              className="actionsContainer-action"
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                justifyContent: "center",
                pointerEvents: "none",
              }}
            >
              <p className="action-title1">Founders</p>
              <p className="action-description">Centauri Lord</p>
              <p className="action-description">Cryaxis</p>
            </div>

            <div
              className="actionsContainer-action"
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                justifyContent: "center",
                pointerEvents: "none",
              }}
            >
              <p className="action-title1">Unity/Web3 Dev </p>
                          <p className="action-description">Centauri Lord</p>
            </div>

            <div
              className="actionsContainer-action"
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                justifyContent: "center",
                pointerEvents: "none",
              }}
            >
              <p className="action-title1">Ecosystem Dev </p>
                          <p className="action-description">Cryaxis</p>
            </div>

            <div
              className="actionsContainer-action"
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                justifyContent: "center",
                pointerEvents: "none",
              }}
            >
              <p className="action-title1">Sound Artist</p>
              <p className="action-description">Prolix</p>
            </div>

            <div
              className="actionsContainer-action"
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                justifyContent: "center",
                pointerEvents: "none",
              }}
            >
              <p className="action-title1">Art</p>
              <p className="action-description">Guerrillaw4r</p>
            </div>

            <div
              className="actionsContainer-action"
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                justifyContent: "center",
                pointerEvents: "none",
              }}
            >
              <p className="action-title1">Discord Team</p>
                          <p className="action-description">Captain Trey</p>
                          <p className="action-description">Vancha</p>
            </div>

            <div
              className="actionsContainer-action"
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                justifyContent: "center",
                pointerEvents: "none",
              }}
            >
             <p className="action-title1">Web Dev</p>
             <p className="action-description">Cryaxis</p>
              
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Team;
