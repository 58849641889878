// packages
import React from "react";

// pages and components

// styles
import "./ClosingSection.css";

const ClosingSection = () => {
  return (
    <div className="closing-container">
      <p className="copyright">© 2023-2024 Proxima The Game</p>
      <a className="terms" href="/#">
        Terms and Conditions
      </a>
    </div>
  );
};

export default ClosingSection;
