// packages
import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import closeMenu from "./assets/closeMenu.svg";
import openMenu from "./assets/openMenu.svg";
import NavbarLeft from "./components/navbar/NavbarLeft";
import NavbarRight from "./components/navbar/NavbarRight";

// pages and components
import Background from "./components/Background";
import Footer from "./components/footer/Footer";
import Factions from "./pages/Factions/Factions";
import SingleFaction from "./pages/Factions/SingleFaction";
import TraitsAndRarities from "./pages/Factions/TraitsAndRarities";
import Game from "./pages/GamePage/GamePage";
import SingleGame from "./pages/Game/SingleGame";
import Home from "./pages/Home/Home";
import Resources from "./pages/Resources/Resources";
import SingleResource from "./pages/Resources/SingleResource";
import Shop from "./pages/Shop/Shop";
import SingleShop from "./pages/Shop/SingleShop";
import GamePage from "./pages/GamePage/GamePage";

import SearchNFT from "./pages/nft_own/nftsearch";
import Supply from "./pages/Supply/supply";
import WalletContext from './WalletContext';

// styles
import "./App.css";

function App() {
    const [open, setOpen] = useState(false);
    const [clicked, setClicked] = useState(false); // Initially set to false
    const [walletAddress, setWalletAddress] = useState('');

    // Effect to skip landing page
    useEffect(() => {
        setClicked(true); // Immediately set to true on mount
    }, []); // Empty dependency array ensures this runs only once, on mount

    // Existing effect for event listeners
    useEffect(() => {
        const handler = (event) => {
            setClicked(true); // This will now always set clicked to true
        };

        document.addEventListener("mousedown", handler);
        document.addEventListener("touchstart", handler);

        return () => {
            document.removeEventListener("mousedown", handler);
            document.removeEventListener("touchstart", handler);
        };
    }, []);

    return (
        <WalletContext.Provider value={{ walletAddress, setWalletAddress }}>
            <div className="App">
                <Router>
                    <Background clicked={clicked} />
                    <div className={`header ${clicked ? "clicked" : "notClicked"}`}>
                        <div className={`nav-area ${open ? "active" : ""}`}>
                            <NavbarLeft open={open} />
                            <NavbarRight open={open} />
                        </div>
                        <div className="hamburger logo">
                            <img
                                className="hamburger-img"
                                src={open ? closeMenu : openMenu}
                                alt="menu"
                                onClick={() => setOpen(!open)}
                            />
                        </div>
                    </div>
                    <div className={`body ${clicked ? "clicked" : "notClicked"}`}>
                        <Routes>
                            <Route path="/" element={<Home />} />
                            <Route path="/game" element={<Game />} />
                            <Route path="/game/gameone" element={<GamePage />} />
                            <Route path="/game/:singleGame" element={<SingleGame />} />
                            <Route path="/factions" element={<Factions />} />
                            <Route path="/factions/:faction" element={<SingleFaction />} />
                            <Route path="/factions/:faction/:subPage" element={<TraitsAndRarities />} />
                            <Route path="/shop" element={<Shop />} />
                            <Route path="/shop/:singleShop" element={<SingleShop />} />
                            <Route path="/resources" element={<Resources />} />
                            <Route path="/resources/:singleResource" element={<SingleResource />} />
                            <Route path="/resources/searchNFT" element={<SearchNFT />} />
                            <Route path="/resources/Supply" element={<Supply />} />
                        </Routes>
                    </div>
                    <div id="modalPlaceholder"></div>
                    <div className={`footer ${clicked ? "clicked" : "notClicked"}`}>
                        <Footer />
                    </div>
                </Router>
            </div>
        </WalletContext.Provider>
    );
}

export default App;
