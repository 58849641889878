// packages
import Tooltip from "@mui/material/Tooltip";
import { ethers } from "ethers";
import React, { useEffect, useState } from "react";

// pages and components
import celestialIcon from "../../../assets/celestial1.png";
import copyIcon from "../../../assets/copyIcon.svg";
import machinaIcon from "../../../assets/machina1.png";
import metamaskIcon from "../../../assets/metamaskIcon.png";
import networkOff from "../../../assets/networkOff.svg";
import noMetamask from "../../../assets/noMetamask.svg";
import proxIcon from "../../../assets/proxIcon.png";
import tellurianIcon from "../../../assets/tellurian1.png";

// styles
import "./StepThree.css";

const StepThree = () => {
  // different states and constants used
  const [openCopyTooltip, setOpenCopyTooltip] = useState(null);
  const [openTokenTooltip, setOpenTokenTooltip] = useState(null);
  const [haveMetaMask, setHaveMetaMask] = useState(true);
  const [chainID, setChainID] = useState("");
  const mtvID = 62621;
  const contactData = [
    {
      icon: proxIcon,
      params: {
        address: "0x9029d1dEDa00e166De55406eAB2608E4efC66D7b",
        symbol: "PROX",
        decimals: 18,
      },
    },
    {
      icon: tellurianIcon,
      params: {
        address: "0x70533ad4fad157351294ee906cc2f09c922d213a",
        symbol: "Tellurian",
        decimals: 0,
      },
    },
    {
      icon: machinaIcon,
      params: {
        address: "0xd8b9c28a7275107cc1b1bc80e578b4d888386a38",
        symbol: "Machina",
        decimals: 0,
      },
    },
    {
      icon: celestialIcon,
      params: {
        address: "0xd97333f247b3d343f8886320beed80bf6cca9d77",
        symbol: "Celestial",
        decimals: 0,
      },
    },
  ];

  useEffect(() => {
    const checkMetaMaskAvailability = () => {
      if (!window.ethereum) {
        setHaveMetaMask(false);
        console.log("input");
      } else {
        setHaveMetaMask(true);
      }
    };

    checkMetaMaskAvailability();
  }, []);

  if (haveMetaMask) {
    const { ethereum } = window;

    // side function to fetch and set chainID
    const getChainID = async () => {
      const id = await ethereum.request({ method: "eth_chainId" });
      setChainID(parseInt(id, 16));
    };
    getChainID();

    // side function to switch chain
    const switchChainHandler = async () => {
      if (ethereum.networkVersion !== mtvID) {
        try {
          await ethereum.request({
            method: "wallet_switchEthereumChain",
            params: [{ chainId: ethers.utils.hexlify(mtvID) }],
          });
        } catch (err) {
          // This error code indicates that the chain has not been added to MetaMask
          if (err.code === 4902) {
            await ethereum.request({
              method: "wallet_addEthereumChain",
              params: [
                {
                  chainName: "MultiVAC Mainnet",
                  chainId: ethers.utils.hexlify(mtvID),
                  nativeCurrency: { name: "MTV", decimals: 18, symbol: "MTV" },
                  rpcUrls: ["https://rpc.mtv.ac"],
                },
              ],
            });
          }
        }
      }
    };

    // side function to add token
    const addToken = async (tokenData, index) => {
      const tokenContract = tokenData.address;
      const tokenSymbol = tokenData.symbol;
      const tokenDecimals = tokenData.decimals;

      try {
        const wasAdded = await ethereum.request({
          method: "wallet_watchAsset",
          params: {
            type: "ERC20",
            options: {
              address: tokenContract,
              symbol: tokenSymbol,
              decimals: tokenDecimals,
            },
          },
        });
        if (wasAdded) {
          toggleClick(index, "token");
        } else {
          console.log("Not Added");
        }
      } catch (error) {
        console.log(error);
      }
    };

    // side function to add to clipboard
    const copyToClipboard = async (address) => {
      await navigator.clipboard.writeText(address);
    };

    // side function for tooltip
    const toggleClick = (index, type) => {
      if (type === "copy") {
        setOpenCopyTooltip(openCopyTooltip === index ? null : index);
        setTimeout(() => {
          setOpenCopyTooltip(null);
        }, "1000");
      }
      if (type === "token") {
        setOpenTokenTooltip(openTokenTooltip === index ? null : index);
        setTimeout(() => {
          setOpenTokenTooltip(null);
        }, "1000");
      }
    };

    ///////////
    //Output///
    ///////////
    // Propose to switch chain in case current one is not MTV
    if (chainID !== mtvID) {
      return (
        <div className="stepContentContainer">
          <div className="leftContentSection">
            <div className="reqNotFul">
              <img
                src={networkOff}
                alt="wrongNetwork"
                className="networkStatus"
              />
              <div style={{ textAlign: "center" }}>
                The currently selected chain is wrong. Please click below to
                switch to MultiVAC Mainnet.
              </div>
              <div className="chainHandlerButton" onClick={switchChainHandler}>
                Add/Switch network
              </div>
            </div>
          </div>
          <div className="rightContentSection">
            <div className="reqNotFul extentions">
              <div className="browserExtentionContainer">
                <div style={{ textAlign: "center", marginTop: "0.5em" }}>
                  Contract data
                </div>
                <div className="extentionDivider"></div>
                <div>
                  <span style={{ color: "var(--blueColor)" }}>
                    Tokens and Metamask
                  </span>
                  <br />
                  If you want to see your current $PROX Token balance or the
                  size of your Machina fleet in your wallet, just click on the
                  respective Metamask-Icon to add the contract automatically.
                </div>
                <div>
                  <span style={{ color: "var(--redColor)" }}>Remarks</span>
                  <br />- You must be connected to the MultiVAC Mainnet before
                  adding the contracts to your Wallet.
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }

    // Propose to add tokens if current chain is MTV
    if (chainID === mtvID) {
      return (
        <div className="stepContentContainer">
          <div className="leftContentSection">
            <div className="variousContracts">
              {contactData.map((contract, index) => (
                <div key={index} className="singleContractContainer">
                  <img
                    className="singleContractIcon"
                    src={contract.icon}
                    alt="Icon"
                  />
                  <div className="singleContractData">
                    <div className="contractName">
                      {contract.params.symbol} Token:
                    </div>
                    <div className="contractContainer">
                      <div className="contract">
                        {contract.params.address.slice(0, 5) +
                          "..." +
                          contract.params.address.slice(38, 42)}
                      </div>
                      <div className="iconContainer">
                        <Tooltip
                          onClose={() => toggleClick(null)}
                          open={openCopyTooltip === index}
                          title="Copied to clipboard"
                          arrow
                          PopperProps={{
                            disablePortal: true,
                          }}
                          disableFocusListener
                          disableHoverListener
                          disableTouchListener
                        >
                          <div className="surroundIcon">
                            <img
                              className="addToClipboard"
                              src={copyIcon}
                              alt="clipboardCopy"
                              onClick={() => {
                                toggleClick(index, "copy");
                                copyToClipboard(contract.params.address);
                              }}
                            />
                          </div>
                        </Tooltip>

                        <Tooltip
                          onClose={() => toggleClick(null)}
                          open={openTokenTooltip === index}
                          title="Token added"
                          arrow
                          PopperProps={{
                            disablePortal: true,
                          }}
                          disableFocusListener
                          disableHoverListener
                          disableTouchListener
                        >
                          <div className="surroundIcon">
                            <img
                              className="addToMetamaskIcon"
                              src={metamaskIcon}
                              alt="addToMetamask"
                              onClick={() => addToken(contract.params, index)}
                            />
                          </div>
                        </Tooltip>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
          <div className="rightContentSection">
            <div className="reqNotFul extentions">
              <div className="browserExtentionContainer">
                <div style={{ textAlign: "center", marginTop: "0.5em" }}>
                  Contract data
                </div>
                <div className="extentionDivider"></div>
                <div>
                  <span style={{ color: "var(--blueColor)" }}>
                    Tokens and Metamask
                  </span>
                  <br />
                  If you want to see your current $PROX Token balance or the
                  size of your Machina fleet in your wallet, just click on the
                  respective Metamask-Icon to add the contract automatically.
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }
  } else {
    return (
      <div className="stepContentContainer">
        <div className="leftContentSection">
          <div className="reqNotFul">
            <img
              src={noMetamask}
              alt="rightNetwork"
              className="networkStatus active"
            />
            <div style={{ textAlign: "center" }}>
              MetaMask Wallet seems to not be installed yet or you are accessing
              this page via a mobile browser.
            </div>
            <div style={{ textAlign: "center" }}>
              Please refer back to Step 1 to set up your wallet.
            </div>
          </div>
        </div>
        <div className="rightContentSection">
          <div className="reqNotFul extentions">
            <div className="browserExtentionContainer">
              <div style={{ textAlign: "center", marginTop: "0.5em" }}>
                Contract data
              </div>
              <div className="extentionDivider"></div>
              <div>
                <span style={{ color: "var(--blueColor)" }}>
                  Tokens and Metamask
                </span>
                <br />
                If you want to see your current $PROX Token balance or the size
                of your Machina fleet in your wallet, just click on the
                respective Metamask-Icon to add the contract automatically.
              </div>
              <div>
                <span style={{ color: "var(--redColor)" }}>Remarks</span>
                <br />- You must be connected to the MultiVAC Mainnet before
                adding the contracts to your Wallet.
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
};

export default StepThree;
