// packages
import React, { useEffect } from "react";
import { useParams } from "react-router-dom";

// pages and components
import BarChart from "../../components/BarChart";
import { extractTraits } from "../../components/functions/extractTraits";
import { prepareChartData } from "../../components/functions/prepareChartData";
import { traitData } from "../../data/traitData";

// styles
import "./TraitsAndRarities.css";

const TraitsAndRarities = () => {
  let { faction } = useParams();

  // get trait data
  const galaxies = extractTraits(traitData, 2, faction, "Galaxies");
  const starFighter = extractTraits(traitData, 2, faction, "Star Fighter");
  const fuselage = extractTraits(traitData, 2, faction, "Fuselage");
  const canopy = extractTraits(traitData, 2, faction, "Canopy");
  const engine = extractTraits(traitData, 2, faction, "Engine");
  const afterBurner = extractTraits(traitData, 2, faction, "After Burner");
  const laser = extractTraits(traitData, 2, faction, "Laser");
  const missile = extractTraits(traitData, 2, faction, "Missile");

  // prepare chart data
  const galaxyData = prepareChartData(galaxies, "Galaxies");
  const starFighterData = prepareChartData(starFighter, "Star Fighter");
  const fuselageData = prepareChartData(fuselage, "Fuselage");
  const canopyData = prepareChartData(canopy, "Canopy");
  const engineData = prepareChartData(engine, "Engine");
  const afterBurnerData = prepareChartData(afterBurner, "After Burner");
  const laserData = prepareChartData(laser, "Laser");
  const missileData = prepareChartData(missile, "Missile");

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, [faction]);

  return (
    <div className="traits">
      <div className="trait-container">
        <BarChart chartData={galaxyData} title={"Trait: Galaxy"} />
      </div>
      <div className="trait-container">
        <BarChart chartData={starFighterData} title={"Trait: Star Fighter"} />
      </div>
      <div className="trait-container">
        <BarChart chartData={fuselageData} title={"Trait: Fuselage"} />
      </div>
      <div className="trait-container">
        <BarChart chartData={canopyData} title={"Trait: Canopy"} />
      </div>
      <div className="trait-container">
        <BarChart chartData={engineData} title={"Trait: Engine"} />
      </div>
      <div className="trait-container">
        <BarChart chartData={afterBurnerData} title={"Trait: After Burner"} />
      </div>
      <div className="trait-container">
        <BarChart chartData={laserData} title={"Trait: Laser"} />
      </div>
      <div className="trait-container">
        <BarChart chartData={missileData} title={"Trait: Missile"} />
      </div>
    </div>
  );
};

export default TraitsAndRarities;
