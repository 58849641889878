// packages
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { HashLink } from "react-router-hash-link";

// pages and components
import arrowLeft from "../../assets/arrowLeft.svg";
import arrowRight from "../../assets/arrowRight.svg";
import epicIcon from "../../assets/epicIcon.png";
import headLogo from "../../assets/headLogo.png";
import metamaskIcon from "../../assets/metamaskIcon.png";
import mtvIcon from "../../assets/mtvIcon.png";
import proxIcon from "../../assets/proxIcon.png";
import StepFive from "./Steps/StepFive";
import StepFour from "./Steps/StepFour";
import StepOne from "./Steps/StepOne";
import StepThree from "./Steps/StepThree";
import StepTwo from "./Steps/StepTwo";

// styles
import "animate.css/animate.min.css";
import "./GettingStarted.css";

const GettingStarted = ({ currentMenu }) => {
  const [currentStep, setCurrentStep] = useState(1);
  const { singleResource } = useParams();

  const chooseStep = (stepNumber) => {
    setCurrentStep(stepNumber);
  };

  const backStep = () => {
    setCurrentStep((prev) => (prev > 1 ? prev - 1 : 1));
  };

  const nextStep = () => {
    setCurrentStep((prev) => (prev < 5 ? prev + 1 : 5));
  };

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, [singleResource]);

  return (
    <>
      <div className="singleMenuPage">
        <div className="singleMenuPageContainer">
          <div className="singleMenuPage-currentSubMenu">
            <h3 className="currentSubMenu-title">{currentMenu.title}</h3>
            <img
              src={currentMenu.icon}
              alt="icon"
              className="currentSubMenu-icon"
            />
          </div>
          <div className="singleMenuPage-actionsContainer">
            <HashLink
              smooth
              to="#singleMenuContent"
              className="actionsContainer-action"
              onClick={() => chooseStep(1)}
            >
              <img src={metamaskIcon} className="action-img" alt="icon" />
              <p className="action-title">1. Set up Metamask</p>
            </HashLink>
            <HashLink
              smooth
              to="#singleMenuContent"
              className="actionsContainer-action"
              onClick={() => chooseStep(2)}
            >
              <img src={mtvIcon} className="action-img" alt="icon" />
              <p className="action-title">2. Add MultiVAC Mainnet</p>
            </HashLink>
            <HashLink
              smooth
              to="#singleMenuContent"
              className="actionsContainer-action"
              onClick={() => chooseStep(3)}
            >
              <img src={proxIcon} className="action-img" alt="icon" />
              <p className="action-title">3. Add tokens to Metamask</p>
            </HashLink>
            <HashLink
              smooth
              to="#singleMenuContent"
              className="actionsContainer-action"
              onClick={() => chooseStep(4)}
            >
              <img src={epicIcon} className="action-img" alt="icon" />
              <p className="action-title">4. Get a ship (Optional)</p>
            </HashLink>
            <HashLink
              smooth
              to="#singleMenuContent"
              className="actionsContainer-action"
              onClick={() => chooseStep(5)}
            >
              <img src={headLogo} className="action-img" alt="icon" />
              <p className="action-title">5. Start playing</p>
            </HashLink>
          </div>
        </div>
      </div>

      <div id="singleMenuContent" className="singleMenuContent">
        <div className="gettingStarted">
          {/*                     */}
          {/* Step number section */}
          {/*                     */}
          <div className="stepNumberContainer">
            <div className="stepWrapper">
              <div className="stepper" onClick={() => chooseStep(1)}>
                <div
                  className={`numberContainer withLine ${
                    currentStep === 1 ? "active" : ""
                  }`}
                >
                  1
                </div>
                <div className="stepNameContainer">
                  <div>Set up Metamask</div>
                </div>
              </div>
              <div className="stepper" onClick={() => chooseStep(2)}>
                <div
                  className={`numberContainer withLine ${
                    currentStep === 2 ? "active" : ""
                  }`}
                >
                  2
                </div>
                <div className="stepNameContainer">
                  <div>Add MultiVAC Mainnet</div>
                </div>
              </div>
              <div className="stepper" onClick={() => chooseStep(3)}>
                <div
                  className={`numberContainer withLine ${
                    currentStep === 3 ? "active" : ""
                  }`}
                >
                  3
                </div>
                <div className="stepNameContainer">
                  <div>Add tokens to Metamask</div>
                </div>
              </div>
              <div className="stepper" onClick={() => chooseStep(4)}>
                <div
                  className={`numberContainer withLine ${
                    currentStep === 4 ? "active" : ""
                  }`}
                >
                  4
                </div>
                <div className="stepNameContainer">
                  <div>Get a ship (Optional)</div>
                </div>
              </div>
              <div className="stepper" onClick={() => chooseStep(5)}>
                <div
                  className={`numberContainer ${
                    currentStep === 5 ? "active" : ""
                  } `}
                >
                  5
                </div>
                <div className="stepNameContainer">
                  <div>Start playing</div>
                </div>
              </div>
            </div>
          </div>

          {/*                     */}
          {/*   content section   */}
          {/*                     */}
          {currentStep === 1 && <StepOne />}
          {currentStep === 2 && <StepTwo />}
          {currentStep === 3 && <StepThree />}
          {currentStep === 4 && <StepFour />}
          {currentStep === 5 && <StepFive />}

          {/*                     */}
          {/*    Move section     */}
          {/*                     */}
          <div className="stepMoveContainer">
            <HashLink
              smooth
              to="#singleMenuContent"
              className="backButtonContainer"
              onClick={() => backStep()}
            >
              <img src={arrowLeft} alt="arrowLeft" className="arrowLeftIcon" />
              <div className="backButton">Back</div>
            </HashLink>
            <HashLink
              smooth
              to="#singleMenuContent"
              className="nextButtonContainer"
              onClick={() => nextStep()}
            >
              <div className="nextButton">Next</div>
              <img
                src={arrowRight}
                alt="arrowRight"
                className="arrowRightIcon"
              />
            </HashLink>
          </div>
        </div>
      </div>
    </>
  );
};

export default GettingStarted;
