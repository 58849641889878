import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyD0-fGRoLJjOUZk0ugJTd9OdfgaJIzxgOY",
  authDomain: "homepage-bb9cc.firebaseapp.com",
  projectId: "homepage-bb9cc",
  storageBucket: "homepage-bb9cc.appspot.com",
  messagingSenderId: "398762204433",
  appId: "1:398762204433:web:3912d0009035082e9b40b0",
};

//   Init firebase
initializeApp(firebaseConfig);

//   Init firestore
const db = getFirestore();

export { db };
