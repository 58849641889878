import React, { useEffect } from "react";
import { AnimationOnScroll } from "react-animation-on-scroll";

const WhitepaperPartThree = () => {
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);

  return (
    <div className="singleSourceWhitepaper">
      <AnimationOnScroll animateIn="animate__fadeInLeftBig" animateOnce={true}>
        <div className="whitepaperBlock">
          <h2>PART III: Introducing $PROX Proxima Game Credits</h2>
        </div>
      </AnimationOnScroll>

      <AnimationOnScroll animateIn="animate__fadeInLeftBig" animateOnce={true}>
        <div className="whitepaperBlock">
          <h3>$PROX token objectives </h3>
          <p className="whitepaperParagraph">
            Our goal is to build a 2D retro sci-fi side scrolling arcade game; a
            game you can pick up and play, a game you want to get the highest
            score stored on the blockchain, a game that has economic vibrancy.
            <div className="block-shift2">
              <p className="bulletPoint">
                Tradable player rewards
                <br />
                $PROX tokens are designed to be Proxima’s fundamental reward
                instrument. By offering MRC-20 tokens, players can earn rewards
                which can be easily traded with others or even leveraged outside
                of the game.
              </p>
              <p className="bulletPoint">
                Aligning incentives
                <br />
                Issuing $PROX tokens to all participants in the economy,
                including third party developers, content creators and the
                community, works to align incentives around Proxima.
              </p>
              <p className="bulletPoint">
                Economic sustainability
                <br />
                $PROX tokens will be distributed in a sustainable way via a
                fixed supply schedule. Not only are we rewarding early adopters,
                but we’re ensuring we can continue to reward new users for years
                to come.
              </p>
            </div>
          </p>
        </div>
      </AnimationOnScroll>

      <AnimationOnScroll animateIn="animate__fadeInLeftBig" animateOnce={true}>
        <div className="whitepaperBlock">
          <h3>$PROX supply and allocation</h3>
          <p className="whitepaperParagraph">
            $PROX tokens have a fixed total supply of 10,000,000,000 (10
            billion) provisionally allocated to the following areas:
          </p>
          <div className="block-shift2">
            <p className="bulletPoint">
              Play to Earn Rewards
              <br />
              35% (3.5 billion) unlocked over 5 years
            </p>
            <p className="bulletPoint">
              Ecosystem and Game Development Fund
              <br />
              10% (1 billion) unlocked over 5 years
            </p>
            <p className="bulletPoint">
              Proxima Reserve
              <br />
              10% (1 billion) unlocked over 12 months
            </p>
            <p className="bulletPoint">
              Community allocation
              <br />
              5% (500 million)
            </p>
            <p className="bulletPoint">
              Token Foundation
              <br />
              5% (500 million) unlocked immediately
            </p>
            <p className="bulletPoint">
              Token Sale
              <br />
              35% (3.5 billion) unlocked over 12 months
            </p>
          </div>
          <h4>Play to Earn Rewards</h4>
          <p className="whitepaperParagraph">
            3,500,000,000 $PROX tokens (35% of total) are reserved for the Play
            to Earn Rewards pool. This is the largest allocation as Play to Earn
            is the key to Proxima. With this rewards pool, we aim to attract a
            large group of active players to participate in the Proxima
            ecosystem. A fixed amount of $PROX tokens will be allocated to a
            variety of Play to Earn campaigns such as Weekend Ranked reward,
            Daily log-in reward system, seasonal rewards, tournaments and other
            TBA special game modes
          </p>
          <h4>Ecosystem and Game Developement Fund</h4>
          <p className="whitepaperParagraph">
            1,000,000,000 $PROX tokens (10% of total) will be set aside as a
            rewards pool to foster community engagement. The purpose of the pool
            is to reward activities which are difficult to quantify but add
            significant value to the game and game economy. Part of the monthly
            allocation will be distributed to the community at the Foundation’s
            discretion. Another part of the monthly allocation will be
            distributed according to what’s decided via monthly governance
            proposals. The Community and Ecosystem Fund includes, but is not
            limited to the following areas. Community activation, Community
            tournament, Community engagement, Content creators, Partnership,
            Referral rewards, Grants (third party developers) and
            marketing.Proxima Reserves
          </p>
          <h4>Proxima Reserves</h4>
          <p className="whitepaperParagraph">
            1,000,000,000 $PROX (10% of total) tokens will be allocated to the
            team behind the Proxima game for the development of the $PROX token.
            Tokens will unlock over 12 months after a successful token sale.
            This is strictly a reserve.
          </p>
          <h4>Community Allocation</h4>
          <p className="whitepaperParagraph">
            500,000,000 $PROX tokens (5% of total) will be allocated to
            community in-game events that reward early adopters. We believe it’s
            important to recognise the early supporters that help us launch
            Proxima, as they will play a significant role in making the game as
            success.
          </p>
          <h4>Token Foundation</h4>
          <p className="whitepaperParagraph">
            500,000,000 $PROX tokens (5% of total) will be allocated to the
            Foundation and unlocked immediately This portion of the token is
            used to provide liquidity to users, exchange partnerships,
            marketing, and other functions the Foundation deems are in the best
            interest of improving the token ecosystem.
          </p>
          <h4>Token Sale</h4>
          <p className="whitepaperParagraph">
            3,500,000,000 $PROX tokens (35% of total) will be sold via a public
            token sale This encourages early use of the token and raises the
            value for all participants in the ecosystem. Funds from the token
            sale will be used to develop the first full release of Proxima.
            Presale 1,000,000,000 tokens at an initial offering price of 0.00015
            USD Vested over 6 months in 5 payments. IDO 1,000,000,000 tokens
            offered at the price of 0.00020 USD Vested over 3 months in 3
            payments. General Sale 1,500,000,000 offered at the price of 0.00025
            USD $PROX tokens unsold from the initial offerings will be sent to a
            burn address and removed permanently from the total supply.
            Liquidity 10% of funds generated to go directly to liquidity.
          </p>
        </div>
      </AnimationOnScroll>

      <AnimationOnScroll animateIn="animate__fadeInLeftBig" animateOnce={true}>
        <div className="whitepaperBlock">
          <h3>$PROX Rewards</h3>
          <p className="whitepaperParagraph">
            $PROX Token holders will be able to earn staking rewards at a set
            APY to be announced, a goal reward when each 250 million is reached
            will also be available.
          </p>
          <p className="whitepaperParagraph">
            $PROX Token holders will be able to earn a percentage share (TBA) of
            the revenue earned from the Proxima in-app purchases store.
          </p>
          <p className="whitepaperParagraph">
            To obtain these rewards holders tokens will have to be locked in the
            Proxima Staking Platform to be released after the general token
            sale, there will be a 7 day unlocking period.
          </p>
        </div>
      </AnimationOnScroll>

      <AnimationOnScroll animateIn="animate__fadeInLeftBig" animateOnce={true}>
        <div className="whitepaperBlock">
          <h3>Governance and voting</h3>
          <p className="whitepaperParagraph">
            $PROX Token holders will be able to vote on token-related proposals
            via decentralized governance.
          </p>
        </div>
      </AnimationOnScroll>
    </div>
  );
};

export default WhitepaperPartThree;
