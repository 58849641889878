// packages
import { ethers } from "ethers";
import React, { useEffect, useState } from "react";

// pages and components
import networkOff from "../../../assets/networkOff.svg";
import networkOn from "../../../assets/networkOn.svg";
import noMetamask from "../../../assets/noMetamask.svg";

// styles
import "./StepTwo.css";

const StepTwo = ({ open }) => {
  // different states and constants used
  const [haveMetaMask, setHaveMetaMask] = useState(true);
  const [chainID, setChainID] = useState("");
  const mtvID = 62621;

  useEffect(() => {
    const checkMetaMaskAvailability = () => {
      if (!window.ethereum) {
        setHaveMetaMask(false);
        console.log("input");
      } else {
        setHaveMetaMask(true);
      }
    };

    checkMetaMaskAvailability();
  }, []);

  if (haveMetaMask) {
    const { ethereum } = window;

    // side function to fetch and set chainID
    const getChainID = async () => {
      const id = await ethereum.request({ method: "eth_chainId" });
      setChainID(parseInt(id, 16));
    };
    getChainID();

    // side function to switch chain
    const switchChainHandler = async () => {
      if (ethereum.networkVersion !== mtvID) {
        try {
          await ethereum.request({
            method: "wallet_switchEthereumChain",
            params: [{ chainId: ethers.utils.hexlify(mtvID) }],
          });
        } catch (err) {
          // This error code indicates that the chain has not been added to MetaMask
          if (err.code === 4902) {
            await ethereum.request({
              method: "wallet_addEthereumChain",
              params: [
                {
                  chainName: "MultiVAC Mainnet",
                  chainId: ethers.utils.hexlify(mtvID),
                  nativeCurrency: { name: "MTV", decimals: 18, symbol: "MTV" },
                  rpcUrls: ["https://rpc.mtv.ac"],
                },
              ],
            });
          }
        }
      }
    };
    if (chainID !== mtvID) {
      return (
        <div className="stepContentContainer">
          <div className="leftContentSection">
            <div className="reqNotFul">
              <img
                src={networkOff}
                alt="wrongNetwork"
                className="networkStatus"
              />
              <div style={{ textAlign: "center" }}>
                You are currently either using a wrong network or have not set
                up MultiVAC Mainnet in your MetaMask Wallet.
              </div>
              <div style={{ textAlign: "center" }}>
                Please click to below button to proceed.
              </div>
              <div className="chainHandlerButton" onClick={switchChainHandler}>
                Add/Switch network
              </div>
            </div>
          </div>
          <div className="rightContentSection">
            <div className="reqNotFul extentions">
              <div className="browserExtentionContainer">
                <div style={{ textAlign: "center", marginTop: "0.5em" }}>
                  MultiVAC - next-generation blockchain
                </div>
                <div className="extentionDivider"></div>
                <div>
                  <span style={{ color: "var(--blueColor)" }}>
                    All-Dimensional sharding
                  </span>
                  <br />
                  MultiVAC breaks through the limitations of blockchain
                  scalability by developing the world’s first fully sharded
                  blockchain with sharded transmission and storage.
                </div>
                <div>
                  <span style={{ color: "var(--blueColor)" }}>
                    High TPS & Infinite Expansion
                  </span>
                  <br />
                  MultiVAC maximizes throughput on every shard while maintaining
                  decentralization and security, allowing decentralized
                  blockchains to achieve industrial capacity.
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }
    if (chainID === mtvID) {
      return (
        <div className="stepContentContainer">
          <div className="leftContentSection">
            <div className="reqNotFul">
              <img
                src={networkOn}
                alt="rightNetwork"
                className="networkStatus active"
              />
              <div style={{ textAlign: "center" }}>
                Your MetaMask Wallet has been set up correctly and is currently
                using MultiVAC Mainnet.
              </div>
              <div style={{ textAlign: "center" }}>
                You can now proceed with the next step.
              </div>
            </div>
          </div>
          <div className="rightContentSection">
            <div className="reqNotFul extentions">
              <div className="browserExtentionContainer">
                <div style={{ textAlign: "center", marginTop: "0.5em" }}>
                  MultiVAC - next-generation blockchain
                </div>
                <div className="extentionDivider"></div>
                <div>
                  <span style={{ color: "var(--blueColor)" }}>
                    All-Dimensional sharding
                  </span>
                  <br />
                  MultiVAC breaks through the limitations of blockchain
                  scalability by developing the world’s first fully sharded
                  blockchain with sharded transmission and storage.
                </div>
                <div>
                  <span style={{ color: "var(--blueColor)" }}>
                    High TPS & Infinite Expansion
                  </span>
                  <br />
                  MultiVAC maximizes throughput on every shard while maintaining
                  decentralization and security, allowing decentralized
                  blockchains to achieve industrial capacity.
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }
  } else {
    return (
      <div className="stepContentContainer">
        <div className="leftContentSection">
          <div className="reqNotFul">
            <img
              src={noMetamask}
              alt="rightNetwork"
              className="networkStatus active"
            />
            <div style={{ textAlign: "center" }}>
              MetaMask Wallet seems to not be installed yet or you are accessing
              this page via a mobile browser.
            </div>
            <div style={{ textAlign: "center" }}>
              Please refer back to Step 1 to set up your wallet.
            </div>
          </div>
        </div>
        <div className="rightContentSection">
          <div className="reqNotFul extentions">
            <div className="browserExtentionContainer">
              <div style={{ textAlign: "center", marginTop: "0.5em" }}>
                MultiVAC - next-generation blockchain
              </div>
              <div className="extentionDivider"></div>
              <div>
                <span style={{ color: "var(--blueColor)" }}>
                  All-Dimensional sharding
                </span>
                <br />
                MultiVAC breaks through the limitations of blockchain
                scalability by developing the world’s first fully sharded
                blockchain with sharded transmission and storage.
              </div>
              <div>
                <span style={{ color: "var(--blueColor)" }}>
                  High TPS & Infinite Expansion
                </span>
                <br />
                MultiVAC maximizes throughput on every shard while maintaining
                decentralization and security, allowing decentralized
                blockchains to achieve industrial capacity.
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
};

export default StepTwo;
