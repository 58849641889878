// packages
import React, { useEffect } from "react";
import { useParams } from "react-router-dom";

// pages and components
import { extractContent } from "../../components/functions/extractContent";
import { menuItemData } from "../../data/menuItemData";
import GettingStarted from "./GettingStarted";

// styles
import "./SingleGame.css";

const SingleGame = () => {
  const { singleGame } = useParams();

  const [currentMenu] = extractContent(menuItemData, 1, "Game").filter(
    (content) => content.search === singleGame
  );

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, [singleGame]);

  return (
    <>
      {singleGame === "gettingstarted" ? (
        <GettingStarted currentMenu={currentMenu} />
      ) : (
        <div></div>
      )}
    </>
  );
};

export default SingleGame;
