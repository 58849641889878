// packages
import React from "react";

// pages and components

// styles
import "./Background.css";

const Background = ({ clicked }) => {
  return (
    <div className="background">

      <div className="background space">
        <div className="background stars"></div>
        <div className="background stars"></div>

        <div className="background stars"></div>
      </div>
      <div className="background bubble">
        <div className="background bubble1"></div>

      </div>
    </div>
  );
};

export default Background;
