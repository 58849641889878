// packages
import React, { useEffect } from "react";
import { useParams } from "react-router-dom";

// pages and components
import { extractContent } from "../../components/functions/extractContent";
import SubMenu from "../../components/SubMenu";
import { menuItemData } from "../../data/menuItemData";

// styles
import "./SingleFaction.css";

const SingleFaction = () => {
  const { faction } = useParams();

  const [currentMenu] = extractContent(menuItemData, 1, "Factions").filter(
    (content) => content.search === faction
  );

  const contents = extractContent(
    menuItemData,
    2,
    "Factions",
    faction,
    "special"
  );

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, [faction]);

  //
  // Return template
  //
  return (
    <SubMenu
      subMenuTitle={currentMenu.title}
      subMenuIcon={currentMenu.icon}
      contents={contents}
      navLinkRelevant={true}
    />
  );
};

export default SingleFaction;
