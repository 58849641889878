import React, { useEffect } from "react";
import { AnimationOnScroll } from "react-animation-on-scroll";

const WhitepaperPartTwo = () => {
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);

  return (
    <div className="singleSourceWhitepaper">
      <AnimationOnScroll animateIn="animate__fadeInLeftBig" animateOnce={true}>
        <div className="whitepaperBlock">
          <h2>User engagement and NFTs</h2>
        </div>
      </AnimationOnScroll>

      <AnimationOnScroll animateIn="animate__fadeInLeftBig" animateOnce={true}>
        <div className="whitepaperBlock">
          <h3>User Engagement</h3>
          <p className="whitepaperParagraph">
            Proxima has a goal to cultivate its growth. How we will achieve this
            will be explained further down below in detail.
          </p>
        </div>
      </AnimationOnScroll>

      <AnimationOnScroll animateIn="animate__fadeInLeftBig" animateOnce={true}>
        <div className="whitepaperBlock">
          <h3>Set up to grow without limits</h3>
          <p className="whitepaperParagraph">
            Proxima has chosen the Multivac Blockchain, because of its L1 Proof
            of stake, trusted sharding technology, peak TPS (Transactions per
            second) of 30784, with it’s design for large-scale, complex
            distributed applications and negligible gas-free transaction costs
            for end users, this will allow near instantaneous peer-to-peer
            trading.
          </p>
          <p className="whitepaperParagraph">
            Proxima will create user experiences that will allow our game to
            access the mainstream gaming market by simplifying the purchase,
            trade, and minting experiences of any Proxima NFT’s, fighter rentals
            and in-game store purchases.
          </p>
        </div>
      </AnimationOnScroll>

      <AnimationOnScroll animateIn="animate__fadeInLeftBig" animateOnce={true}>
        <div className="whitepaperBlock">
          <h3>Ownership and earn driven retention</h3>
          <p className="whitepaperParagraph">
            The Proxima team have studied the metrics of user retention, Peer
            analysis shows that NFT gaming can increase D30 retention from a
            typical 8% (“D1 Retention - Historical Timeline”) up to 80–90%
            (source: Axie Infinity, My Crypto Heroes).
          </p>
          <p className="whitepaperParagraph">
            This is one of the key’s to the Proxima team and it’s plans. This is
            the reason why we are laser focused on NFT ownership and Play to
            Earn. As players are able to extract, manifest value from playing
            the game beyond just pure entertainment, they have a much stronger
            incentive to return and become game advocates.
          </p>
        </div>
      </AnimationOnScroll>

      <AnimationOnScroll animateIn="animate__fadeInLeftBig" animateOnce={true}>
        <div className="whitepaperBlock">
          <h3>Going mobile</h3>
          <p className="whitepaperParagraph">
            Proxima has a fundamental aspiration to eventually release mobile
            versions on iOS / Android operating systems. Unlocking its ability
            to access an even larger audience. We have studied the metrics, they
            have more users because they’re highly accessible, making them the
            perfect avenue for mainstream adoption of NFT gaming. Peer analysis
            on a play to earn game title shows that over 82% of active players
            are using mobile devices (Source: Axie Infinity). Mobile
            distribution will help further accelerate the adoption of Proxima
            and the wider blockchain gaming ecosystem.
          </p>
        </div>
      </AnimationOnScroll>

      <AnimationOnScroll animateIn="animate__fadeInLeftBig" animateOnce={true}>
        <div className="whitepaperBlock">
          <h3>eSports meets the blockchain</h3>
          <p className="whitepaperParagraph">
            2023 will see the launch of the quest to find the inaugural Proxima
            World Champion, 16 finalist’s will qualify for the final to be held
            in London at the end of the year. Each month the player with the
            highest score will take their place in the final, also 4 other
            players will be selected from competitions and community
            initiatives.
          </p>
          <p className="whitepaperParagraph">
            Each of the 16 finalists will win an all expenses paid trip to
            London at the end of 2023 to battle it out against each other,
            broadcast live on twitch. A winner takes all grand prize of $10,000
            USD paid in Proxima Tokens for the eventual winner.
          </p>
          <p className="whitepaperParagraph">More details to be announced.</p>
        </div>
      </AnimationOnScroll>

      <AnimationOnScroll animateIn="animate__fadeInLeftBig" animateOnce={true}>
        <div className="whitepaperBlock">
          <h3>Proxima NFT’s and their relationship with the game</h3>
          <p className="whitepaperParagraph">
            The Proxima team has worked tirelessly to develop how NFT’s will be
            implemented in the Proxima ecosystem, a breakthrough in the
            relationship between NFT’s and gaming.
          </p>
          <p className="whitepaperParagraph">
            Proxima traits are designed to specifically give the NFT Holder or
            Renter an edge during gameplay, they will not however be overpowered
            to the detriment of the games mechanics.
          </p>
          <p className="whitepaperParagraph">
            The link between the NFT’s playable character and the games generic
            playable character has to maintain integrity, so that all users can
            enjoy the experience of playing Proxima.
          </p>
          <p className="whitepaperParagraph">
            Non NFT holding players will be able to earn Proxima tokens from
            playing the game and then use those to rent any of the games 9999
            NFT’s or purchase ingame power-ups. Players will also be able to
            purchase from a DEX or CEX upon listing Proxima tokens.
          </p>
          <p className="whitepaperParagraph">
            A design is in place that will ensure all 9999 NFT’s will have
            significant attributes to attract all users to spend their Proxima
            tokens on renting them.
          </p>
          <p className="whitepaperParagraph">
            Proxima NFT holders wallet’s will always maintain full ownership of
            the NFT in their designated wallet. Proxima will use Smart
            Contract’s to verify the NFT/ Wallet relationship and Proxima tokens
            will then be distributed accordingly.
          </p>
        </div>
      </AnimationOnScroll>

      <AnimationOnScroll animateIn="animate__fadeInLeftBig" animateOnce={true}>
        <div className="whitepaperBlock">
          <h3>NFT Conquest Awards</h3>
          <p className="whitepaperParagraph">
            When players connect to Proxima Online via their Wallet, not only
            will they be able to verify placement on the high score table but
            also earn limited edition NFT conquest awards.
          </p>
          <p className="whitepaperParagraph">
            Just like PS Trophies and Xbox Achievements Proxima players will be
            able to unlock NFT’s by carrying out specific tasks in-game.
          </p>
          <p className="whitepaperParagraph">
            Conquest awards NFT holders wallet’s will always maintain full
            ownership of the NFT in their designated wallet. Proxima will use
            Smart Contract’s to verify the NFT/Wallet relationship.
          </p>
        </div>
      </AnimationOnScroll>

      <AnimationOnScroll animateIn="animate__fadeInLeftBig" animateOnce={true}>
        <div className="whitepaperBlock">
          <h3>NFT Traits Overview</h3>
          <p className="whitepaperParagraph">
            Proxima NFT’s eight traits directly translate to how the fighter
            perform in-game. Each faction’s NFT’s will have a unique combination
            of the following.
          </p>
          <div className="block-shift2">
            <p className="bulletPoint">
              Afterburner
              <br />4 traits that will effect the fighters agility in-game.
            </p>
            <p className="bulletPoint">
              Canopy
              <br />6 traits that will effect the fighters ability to withstand
              damage in-game.
            </p>
            <p className="bulletPoint">
              Engine
              <br />4 traits that will effect the fighters propulsion in-game.
            </p>
            <p className="bulletPoint">
              Fighter
              <br />
              12 traits that will provide a score increase when downing certain
              enemies ingame.
            </p>
            <p className="bulletPoint">
              Fusealge
              <br />4 traits that will effect the fighters visibility to the
              enemy in-game.
            </p>
            <p className="bulletPoint">
              Galaxy
              <br />
              12 traits that will signify each of the 12 levels in Proxima, at
              the start of that given level a thirty second invincibility timer
              will start.
            </p>
            <p className="bulletPoint">
              Laser
              <br />
              10 traits that will signify the fighters ability to inflict damage
              to the enemy ingame.
            </p>
            <p className="bulletPoint">
              Missile
              <br />8 traits that will signify the fighters ability to inflict
              damage to the enemy ingame.
            </p>
          </div>
        </div>
      </AnimationOnScroll>
    </div>
  );
};

export default WhitepaperPartTwo;
