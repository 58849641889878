import React, { useEffect } from "react";
import { AnimationOnScroll } from "react-animation-on-scroll";

const WhitepaperPartOne = () => {
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);

  return (
    <div className="singleSourceWhitepaper">
      <AnimationOnScroll animateIn="animate__fadeInLeftBig" animateOnce={true}>
        <div className="whitepaperBlock">
          <h2>Enter the World of Proxima</h2>
        </div>
      </AnimationOnScroll>

      <AnimationOnScroll animateIn="animate__fadeInLeftBig" animateOnce={true}>
        <div className="whitepaperBlock">
          <h3>What is PROXIMA?</h3>
          <p className="whitepaperParagraph">
            Proxima is a free-to-play side scrolling sci-fi shooter game where
            players compete to amass the highest score through 12 action packed
            levels, to ultimately become the Proxima champion.
          </p>
          <p className="whitepaperParagraph">
            The game features 3 factions, The Tellurian, The Celestial and The
            Machina.
          </p>
          <p className="whitepaperParagraph">
            Each faction will have a maximum NFT mint of 3333 craft, giving
            Proxima a total of 9999 NFTs that will never increase. All NFT’s
            trait’s will translate into ingame attributes to enhance your quest
            to defeat the Satoshi Empire.
          </p>
          <p className="whitepaperParagraph">
            In Proxima, players can completely own their digital items, giving
            them the freedom to trade, rent, sell, and use their craft within or
            beyond the game – just like owning real, tangible items.
          </p>
        </div>
      </AnimationOnScroll>

      <AnimationOnScroll animateIn="animate__fadeInLeftBig" animateOnce={true}>
        <div className="whitepaperBlock">
          <h3>Our Mission</h3>
          <p className="whitepaperParagraph">
            The current state of the gaming industry is a behemoth, being built
            on onesided models that limit user ownership and stifle
            player-driven economies . We believe players should truly own their
            items in any game; more than that, we believe players should be
            rewarded for the time and effort they invest in playing a game and
            contributing to that game’s community. This is why we are building
            Proxima.
          </p>
          <p className="whitepaperParagraph">
            The items you own are set up to have greater utility beyond just
            playing the game as you can trade them with anyone, even outside the
            game. No one – including us – should be able to take away what
            belongs to you.
          </p>
        </div>
      </AnimationOnScroll>

      <AnimationOnScroll animateIn="animate__fadeInLeftBig" animateOnce={true}>
        <div className="whitepaperBlock">
          <h3>Changing the game with NFTs</h3>
          <p className="whitepaperParagraph">
            Non-fungible tokens (NFTs tokens) are unique digital assets that can
            be owned, sold, or traded. Ownership is tracked via the Multivac
            blockchain, ensuring trustless asset security. In Proxima, this
            technology is applied to in-game spacecraft. Like a bridge between
            the virtual and physical world, NFTs provide us and our users with a
            transparent way to assign, verify, and maintain ownership. The
            result? The ability to create a vibrant game that rewards both the
            developer and the player, building functional relationships between
            game and player.
          </p>
        </div>
      </AnimationOnScroll>

      <AnimationOnScroll animateIn="animate__fadeInLeftBig" animateOnce={true}>
        <div className="whitepaperBlock">
          <h3>Proxima Token</h3>
          <p className="whitepaperParagraph">
            Our goal is to build a classic sci-fi side scrolling arcade game; a
            game you can pick up and play, a game you want to get the highest
            score stored on the blockchain, a game that has economic vibrancy.
          </p>
          <div className="block-shift2">
            <p className="bulletPoint">
              Tradable player rewards Proxima tokens are designed to be
              Proxima’s fundamental reward instrument. By offering MRC-20
              tokens, players can earn rewards which can be easily traded with
              others or even leveraged outside of the game.
            </p>
            <p className="bulletPoint">
              Aligning incentives Issuing Proxima tokens to all participants in
              the economy, including third party developers, content creators
              and the community, works to align incentives around Proxima.
            </p>
            <p className="bulletPoint">
              Economic sustainability Proxima tokens will be distributed in a
              sustainable way via a fixed supply schedule. Not only are we
              rewarding early adopters, but we’re ensuring we can continue to
              reward new users for years to come.
            </p>
          </div>
        </div>
      </AnimationOnScroll>

      <AnimationOnScroll animateIn="animate__fadeInLeftBig" animateOnce={true}>
        <div className="whitepaperBlock">
          <h3>Play to Earn</h3>
          <p className="whitepaperParagraph">
            Play to Earn means you can earn money, or assets with real utility,
            just by playing the game. In Proxima, this is seen through the
            ability to earn in-game items that you can sell for real-world cash,
            or use to help you become more competitive in-game. Providing
            players with assets they can own, places they can trade, and rewards
            for their time is – to us – the future of gaming. NFTs and virtual
            currency tokens have provided us the tools to pave the way for this
            future, the final piece of the puzzle is to implement truly
            rewarding and sustainable Play to Earn mechanics. The Proxima token
            is our answer to this – designed to form the backbone of Proxima’s
            Play to Earn economy.
          </p>
        </div>
      </AnimationOnScroll>

      <AnimationOnScroll animateIn="animate__fadeInLeftBig" animateOnce={true}>
        <div className="whitepaperBlock">
          <h3>Revenue streams</h3>
          <p className="whitepaperParagraph">
            Proxima is built using a Games as a Service model. Instead of
            relying on a oneoff purchase, we provide a game that is free to
            play, releasing optional content and features that work to enrich
            and expand the game, without being a requirement to play the game.
            Below is an explanation of how in-game purchases work in Proxima.
          </p>
        </div>
      </AnimationOnScroll>

      <AnimationOnScroll animateIn="animate__fadeInLeftBig" animateOnce={true}>
        <div className="whitepaperBlock">
          <h3>NFT rental on Proxima</h3>
          <p className="whitepaperParagraph">
            All Proxima NFTs will be in the placed in the rental store., any
            player can then purchase the use of that NFT for 5 games with
            Proxima tokens, each NFT will can be rented up to 100 times in a 24
            hour period, this will give all NFT holders the ability to earn
            Proxima Tokens simply by holding a Proxima NFT.
          </p>
          <p className="whitepaperParagraph">
            Star Fighter rental will launch with a price of Proxima tokens
            equivalent to 0.10 usd, there will be no limit to the amount of
            NFT’s per wallet.
          </p>
          <p className="whitepaperParagraph">
            There will be a rental limit of 100 per NFT when the game is
            released, the 100 usage limit will increase to fulfil the needs of
            the game.
          </p>
          <p className="whitepaperParagraph">
            Revenue generated from the Proxima Rental store will be split 75% to
            holders of Proxima NFT and 25% for the game ecosystem.
          </p>
        </div>
      </AnimationOnScroll>

      <AnimationOnScroll animateIn="animate__fadeInLeftBig" animateOnce={true}>
        <div className="whitepaperBlock">
          <h3>In-game Items</h3>
          <p className="whitepaperParagraph">
            Proxima players can head to the in-game store and purchase items in
            order to access special content or features. To power-up their play
            with items including extra lives, weapon and movement modifications.
          </p>
          <p className="whitepaperParagraph">
            Revenue generated from the Proxima in-game item store will be split
            between token holders and the ecosystem and development fund.
          </p>
          <p className="whitepaperParagraph">
            Percentage splits to be announced in the near future.
          </p>
        </div>
      </AnimationOnScroll>
    </div>
  );
};

export default WhitepaperPartOne;
