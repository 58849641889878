import React, { useState, useEffect, useContext } from 'react';
import { Helmet } from 'react-helmet';
import { useNavigate } from 'react-router-dom';
import Web3 from 'web3';
import abi from './general_abi.json';
import './nftsearch.css';
import WalletContext from '../../WalletContext';

const NFTSearch = () => {
    const navigate = useNavigate();
    const [contractAddress, setContractAddress] = useState('');
    const [nftData, setNftData] = useState([]);
    const [isSearching, setIsSearching] = useState(false);
    const [searchPerformed, setSearchPerformed] = useState(false);
    const [recipientAddresses, setRecipientAddresses] = useState({});
    const [transferredNFTs, setTransferredNFTs] = useState({});
    const [showTransferred, setShowTransferred] = useState(true);
    const [showNotTransferred, setShowNotTransferred] = useState(true);
    const [tokenIDFilter, setTokenIDFilter] = useState('');
    const { walletAddress } = useContext(WalletContext);

    useEffect(() => {
        const isMobileBrowser = () => /Mobi|Android/i.test(navigator.userAgent);
        if (isMobileBrowser()) {
            alert("Mobile not supported.");
            setTimeout(() => navigate(-1), 1);
        }
    }, [navigate]);

    const handleSearch = async () => {
        setIsSearching(true);
        setSearchPerformed(true); // Indicate that a search has been performed
        const requestData = { walletAddress, contractAddress };

        try {
            const response = await fetch("https://singulayerty.com/api/PROX/getNFTs", {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(requestData),
            });

            if (response.ok) {
                const data = await response.json();
                setNftData(data);
            } else {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
        } catch (error) {
            console.error("There was an error fetching the NFTs:", error);
        } finally {
            setIsSearching(false);
        }
    };

    const handleTransfer = async (tokenId, recipientAddress) => {
        if (!window.ethereum) return alert('Please install MetaMask.');

        const web3 = new Web3(window.ethereum);
        const contract = new web3.eth.Contract(abi, contractAddress);

        try {
            await window.ethereum.request({ method: 'eth_requestAccounts' });
            const transactionParameters = {
                to: contractAddress,
                from: walletAddress,
                data: contract.methods.safeTransferFrom(walletAddress, recipientAddress, tokenId).encodeABI(),
            };

            await window.ethereum.request({
                method: 'eth_sendTransaction',
                params: [transactionParameters],
            });
            alert('Transfer successful');

            // Mark the NFT as transferred
            setTransferredNFTs(prev => ({ ...prev, [tokenId]: true }));
        } catch (error) {
            alert('Error during transfer: ' + error.message);
        }
    };

    const handleRecipientAddressChange = (tokenId, address) => {
        setRecipientAddresses(prev => ({ ...prev, [tokenId]: address }));
    };

    return (
        <>
            <div className="nft-search-bar">
                <input
                    type="text"
                    placeholder="Enter contract address"
                    value={contractAddress}
                    onChange={(e) => setContractAddress(e.target.value)}
                />
                <div className="checkbox-container">
                    <input
                        type="checkbox"
                        id="showTransferred"
                        checked={showTransferred}
                        onChange={() => setShowTransferred(!showTransferred)}
                    />
                    <label htmlFor="showTransferred">Transferred NFTs</label>

                    <input
                        type="checkbox"
                        id="showNotTransferred"
                        checked={showNotTransferred}
                        onChange={() => setShowNotTransferred(!showNotTransferred)}
                    />
                    <label htmlFor="showNotTransferred">Untransferred NFTs</label>
                </div>
                <button onClick={handleSearch}>Search</button>
            </div>
            {isSearching && <p className="searching-text">Searching... Please wait...</p>}
            {!isSearching && searchPerformed && nftData.length === 0 && <p className="searching-text">NO NFTS FOUND!</p>}
            <div className="nft-results">
                {searchPerformed && nftData.length > 0 && (
                    <input
                        type="text"
                        placeholder="Search by Token ID"
                        value={tokenIDFilter}
                        onChange={(e) => setTokenIDFilter(e.target.value)}
                        className="token-id-search-input"
                    />
                )}
                {nftData
                    .filter(nft => nft.token_id.toString().startsWith(tokenIDFilter))
                    .map((nft) => {
                        const shouldBeShown = (transferredNFTs[nft.token_id] && showTransferred) ||
                            (!transferredNFTs[nft.token_id] && showNotTransferred);
                        if (!shouldBeShown) return null;

                        return (
                            <div key={nft.token_id} className="nft-container">
                                <div className="nft-token-id">Token ID: {nft.token_id}</div>
                                <div className="nft-metadata">
                                    Metadata: <a href={nft.metadata} target="_blank" rel="noopener noreferrer">{nft.metadata}</a>
                                </div>
                                {!transferredNFTs[nft.token_id] ? (
                                    <>
                                        <input
                                            type="text"
                                            placeholder="Recipient address"
                                            value={recipientAddresses[nft.token_id] || ''}
                                            onChange={(e) => handleRecipientAddressChange(nft.token_id, e.target.value)}
                                            className="recipient-address-input"
                                        />
                                        <button
                                            className="transfer-nft-button"
                                            onClick={() => handleTransfer(nft.token_id, recipientAddresses[nft.token_id])}
                                        >
                                            Transfer NFT
                                        </button>
                                    </>
                                ) : null}
                            </div>
                        );
                    })}
            </div>
        </>
    );
};

export default NFTSearch;

