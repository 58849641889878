// packages
import { ethers } from "ethers";
import React, { useEffect, useState, useContext } from "react";
import { Portal } from "react-portal";

// pages and components
import celestialIMG from "../assets/celestial1.png";
import machinaIMG from "../assets/machina1.png";
import proxIcon from "../assets/proxIcon.png";
import tellurianIMG from "../assets/tellurian1.png";
import walletIn from "../assets/walletIn.svg";
import walletOut from "../assets/walletOut.svg";
import celestialABI from "../data/celestialABI.json";
import machinaABI from "../data/machinaABI.json";
import proxABI from "../data/proxABI.json";
import tellurianABI from "../data/tellurianABI.json";
import WalletContext from '../WalletContext';

// styles
import "./WalletComponent.css";

const WalletComponent = ({ open }) => {
  // different states and constants used
  const [haveMetaMask, setHaveMetaMask] = useState(true);
    const [isConnected, setIsConnected] = useState(false);
    const { setWalletAddress } = useContext(WalletContext);
  const [accountAddress, setAccountAddress] = useState("");
  const [accountBalance, setAccountBalance] = useState("");
  const [tellurianBalance, setTellurianBalance] = useState("");
  const [machinaBalance, setMachinaBalance] = useState("");
  const [celestialBalance, setCelestialBalance] = useState("");
  const [chainID, setChainID] = useState("");
  const mtvID = 62621;
  const proxContractAddress = "0x9029d1dEDa00e166De55406eAB2608E4efC66D7b";
  const tellurianContractAddress = "0x70533ad4fad157351294ee906cc2f09c922d213a";
  const machinaContractAddress = "0xd8b9c28a7275107cc1b1bc80e578b4d888386a38";
  const celestialContractAddress = "0xd97333f247b3d343f8886320beed80bf6cca9d77";

  useEffect(() => {
    const checkMetaMaskAvailability = () => {
      if (!window.ethereum) {
        setHaveMetaMask(false);
        console.log("input");
      } else {
        setHaveMetaMask(true);
      }
    };

    checkMetaMaskAvailability();
  }, []);

  if (haveMetaMask) {
    const { ethereum } = window;

    // core function to connect to wallet
    const connectWallet = async () => {
      if (isConnected) {
        window.location.reload();
      } else {
        try {
          if (!ethereum) {
            setHaveMetaMask(false);
          } else {
            const accounts = await ethereum.request({
              method: "eth_requestAccounts",
            });

            setWalletAddress(accounts[0]);
            setIsConnected(true);
            getBalance(accounts[0]);
            getChainID();
          }
        } catch (error) {
          setIsConnected(false);
        }
      }
    };

    // side function to get, transform and store balance
    const getBalance = async (account) => {
      const provider = new ethers.providers.Web3Provider(ethereum);

      //prox coin balance
      const proxContract = new ethers.Contract(
        proxContractAddress,
        proxABI,
        provider
      );
      let proxBalance = await proxContract.balanceOf(account);
      let proxBal = ethers.utils.formatEther(proxBalance);
      setAccountBalance(proxBal);

      //tellurian ship balance
      const tellurianContract = new ethers.Contract(
        tellurianContractAddress,
        tellurianABI,
        provider
      );
      let tellurianBalanceOf = await tellurianContract.balanceOf(account);
      setTellurianBalance(tellurianBalanceOf);

      //machina ship balance
      const machinaContract = new ethers.Contract(
        machinaContractAddress,
        machinaABI,
        provider
      );
      let machinaBalanceOf = await machinaContract.balanceOf(account);
      setMachinaBalance(machinaBalanceOf);

      //celestial ship balance
      const celestialContract = new ethers.Contract(
        celestialContractAddress,
        celestialABI,
        provider
      );
      let celestialBalanceOf = await celestialContract.balanceOf(account);
      setCelestialBalance(celestialBalanceOf);
    };

    // side function to fetch chainID
    const getChainID = async () => {
      const id = await ethereum.request({ method: "eth_chainId" });
      setChainID(parseInt(id, 16));
    };

    // side function to update states after account changed
    const accountChangedHandler = (accounts) => {
      setWalletAddress(accounts);
      getBalance(accounts.toString());
      getChainID();
    };

    // side function to reload after chain changed
    const chainChangedHandler = (chainID) => {
      window.location.reload();
    };

    // side function to switch chain
    const switchChainHandler = async () => {
      if (window.ethereum.networkVersion !== mtvID) {
        try {
          await window.ethereum.request({
            method: "wallet_switchEthereumChain",
            params: [{ chainId: ethers.utils.hexlify(mtvID) }],
          });
        } catch (err) {
          // This error code indicates that the chain has not been added to MetaMask
          if (err.code === 4902) {
            await window.ethereum.request({
              method: "wallet_addEthereumChain",
              params: [
                {
                  chainName: "MultiVAC Mainnet",
                  chainId: ethers.utils.hexlify(mtvID),
                  nativeCurrency: { name: "MTV", decimals: 18, symbol: "MTV" },
                  rpcUrls: ["https://rpc.mtv.ac"],
                },
              ],
            });
          }
        }
      }
    };

    // // add event listeners
    if (ethereum) {
      ethereum.on("accountsChanged", accountChangedHandler);
      ethereum.on("chainChanged", chainChangedHandler);
    }

    return (
      <div className={`nav right ${open ? "active" : ""}`}>
        {/* Login/Logout Button */}
        {haveMetaMask && (
          <div
            className="logo-containr"
            style={{ cursor: "pointer" }}
            onClick={connectWallet}
          >
            <img
              className={`${isConnected ? "walletOut-logo" : "walletIn-logo"}`}
              src={isConnected ? walletOut : walletIn}
              alt="wallet"
            />
          </div>
        )}

        {/* Connected Wallet Address */}
        {isConnected && (
          <div className="addressContainer">
            <p className="address">
              {accountAddress.slice(0, 5) +
                "..." +
                accountAddress.slice(38, 42)}
            </p>
          </div>
        )}

        {/* Display Tokens if current Network is MTV */}
        {isConnected && chainID === mtvID && (
          <div className="balanceContainer">
            <img src={proxIcon} alt="proxIcon" className="prox-logo" />
            <p className="balance">{Number(accountBalance).toFixed(2)}</p>

            <img
              src={tellurianIMG}
              alt="tellurianIcon"
              className="tellurian-logo"
            />
            <p className="balance">{Number(tellurianBalance).toFixed(0)}</p>

            <img src={machinaIMG} alt="machinaIcon" className="machina-logo" />
            <p className="balance">{Number(machinaBalance).toFixed(0)}</p>

            <img
              src={celestialIMG}
              alt="celestialIcon"
              className="celestial-logo"
            />
            <p className="balance">{Number(celestialBalance).toFixed(0)}</p>
          </div>
        )}

        {/* Modal to be added here */}
        {isConnected && chainID !== mtvID && (
          <Portal
            node={document && document.getElementById("modalPlaceholder")}
          >
            <div className="networkModal" onClick={switchChainHandler}>
              <p>
                Wrong network detected. Click here to switch to MultiVAC
                Mainnet.
              </p>
            </div>
          </Portal>
        )}
      </div>
    );
  } else {
    return (
      <div className={`nav right ${open ? "active" : ""}`}>
        <div className="logo-containr" style={{ cursor: "pointer" }}>
          <img className="walletIn-logo" src={walletIn} alt="wallet" />
        </div>

        <div className="addressContainer">
          <p className="address">n/a</p>
        </div>

        <div className="balanceContainer">
          <p className="balance">n/a</p>
        </div>
      </div>
    );
  }
};

export default WalletComponent;
