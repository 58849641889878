// packages
import React from "react";

// pages and components
import GenericCard from "./GenericCard";

// styles
import "./SubMenu.css";

const SubMenu = ({ subMenuTitle, subMenuIcon, contents, navLinkRelevant }) => {
  return (
    <div className="singleMenuPage">
      <div className="singleMenuPageContainer">
        <div className="singleMenuPage-currentSubMenu">
          <h3 className="currentSubMenu-title">{subMenuTitle}</h3>
          <img src={subMenuIcon} alt="icon" className="currentSubMenu-icon" />
        </div>
        <div className="singleMenuPage-actionsContainer">
          {contents.map((content, index) => {
            return (
              <GenericCard
                content={content}
                key={index}
                linkType={"external"}
                navLinkRelevant={navLinkRelevant}
              />
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default SubMenu;
