// packages
import React, { useEffect } from "react";

// pages and components
import { extractContent } from "../../components/functions/extractContent";
import Menu from "../../components/Menu";
import { menuItemData } from "../../data/menuItemData";

// styles
import "./Factions.css";

const Factions = () => {
  const contents = extractContent(menuItemData, 1, "Factions");

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);

  return (
    <div className="factions">
      {contents.map((content, index) => {
        return <Menu content={content} key={index} />;
      })}
    </div>
  );
};

export default Factions;
