import React, { useEffect, useRef } from "react";
import { Helmet } from "react-helmet";
import { useNavigate } from 'react-router-dom';
import { menuItemData } from "../../../data/menuItemData";

// packages
import { UAParser } from "ua-parser-js";

// pages and components
import headLogo from "../../../assets/headLogo.png";


// styles
import "./StepFive.css";

const StepFive = () => {
  var parser = new UAParser();
  var result = parser.getDevice();

  return (
    <div className="stepContentContainer">
      <div className="leftContentSection">
        <div className="reqNotFul">
          <img
            src={headLogo}
            alt="wrongNetwork"
            style={{ width: "7em", height: "7em" }}
          />

          {result.type === undefined ? (
            <>
              <div style={{ textAlign: "center" }}>
                Congratulations! You have successfully completed all preliminary
                steps to get started.
              </div>
              <div style={{ textAlign: "center" }}>
                Click on the button below to start your Proxima journey.
              </div>
              <a
                              href="https://proximathegame.com/game/gameone"
                className="chainHandlerButton"
              >
                Play
              </a>
            </>
          ) : (
            <>
              <div style={{ textAlign: "center" }}>Good job so far!</div>
              <div style={{ textAlign: "center", maxWidth: "30em" }}>
                But unfortunately it seems you are trying to play the game from
                a mobile browser.
              </div>
              <div style={{ textAlign: "center", maxWidth: "30em" }}>
                Please switch over to a desktop device to start your journey
                with Proxima.
              </div>
            </>
          )}
        </div>
      </div>
      {/* <div className="rightContentSection">
        <div className="reqNotFul extentions">
          <div className="browserExtentionContainer">
            <div style={{ textAlign: "center", marginTop: "0.5em" }}>
              Description
            </div>
            <div className="extentionDivider"></div>
            <div>stuff</div>
          </div>
        </div>
      </div> */}
    </div>
  );
};

export default StepFive;
