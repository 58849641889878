export const extractContent = (
  data,
  depth,
  mainMenuTerm,
  subMenuTerm,
  search
) => {
  let result = [];

  data.forEach((mainMenu) => {
    if (depth === 0) {
      result = [...result, mainMenu];
    }

    if (depth === 1) {
      if (mainMenu.title === mainMenuTerm) {
        mainMenu.subMenu.forEach((subMenu) => {
          result = [...result, subMenu];
        });
      }
    }

    if ((search = "special")) {
      if (depth === 2) {
        if (mainMenu.title === mainMenuTerm) {
          mainMenu.subMenu.forEach((subMenu) => {
            if (subMenu.title === subMenuTerm) {
              subMenu.subMenu.forEach((subSubMenu) => {
                result = [...result, subSubMenu];
              });
            }
          });
        }
      }
    }

    if (depth === 2) {
      if (mainMenu.title === mainMenuTerm) {
        mainMenu.subMenu.forEach((subMenu) => {
          if (subMenu.search === subMenuTerm) {
            subMenu.subMenu.forEach((subSubMenu) => {
              result = [...result, subSubMenu];
            });
          }
        });
      }
    }
  });

  //   function return parameter
  return result;
};
