export const prepareChartData = (traitData, title) => {
  const data = {
    labels: traitData.map((subTrait) => subTrait.name),
    datasets: [
      {
        label: `${title} in total`,
        data: traitData.map((subTrait) => subTrait.total),
        backgroundColor: ["#01a8f8"],
        borderRadius: ["8"],
        hoverBackgroundColor: ["#f80201"],
      },
      {
        label: `${title} in percent`,
        data: traitData.map((subTrait) => subTrait.percent),
        backgroundColor: ["#00dcf7"],
        borderRadius: ["8"],
        hoverBackgroundColor: ["#f80201"],
      },
    ],
  };

  return data;
};
