// packages
import React from "react";
import { Link } from "react-router-dom";

// pages and components
import { menuItemData } from "../../data/menuItemData";

// styles
import "./LinkSection.css";
const LinkSection = () => {
  return (
    <div className="link-container">
      {menuItemData.map((menu, index) => {
        return (
          <ul className="link-box" key={index}>
            <Link className="link-title" to={menu.src}>
              {menu.title}
            </Link>
            {menu.subMenu &&
              menu.subMenu.map((subMenu, index) => {
                if (subMenu.type === "externalURL") {
                  return (
                    <li className="link-content" key={index}>
                      <a href={subMenu.src} className="link-link">
                        {subMenu.title}
                      </a>
                    </li>
                  );
                }
                return (
                  <li className="link-content" key={index}>
                    <Link to={`${subMenu.navLink}`} className="link-link">
                      {subMenu.title}
                    </Link>
                  </li>
                );
              })}
          </ul>
        );
      })}
    </div>
  );
};

export default LinkSection;
