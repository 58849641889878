// packages
import React from "react";

// pages and components
import appleIcon from "../../../assets/appleIcon.png";
import braveIcon from "../../../assets/braveIcon.png";
import chromeIcon from "../../../assets/chromeIcon.png";
import firefoxIcon from "../../../assets/firefoxIcon.png";
import playstoreIcon from "../../../assets/playstoreIcon.png";

// styles
import "./StepOne.css";

const StepOne = ({ open }) => {
  return (
    <div className="stepContentContainer">
      <div className="leftContentSection">
        <div className="reqNotFul extentions">
          <div className="browserExtentionContainer">
            <div style={{ textAlign: "center", marginTop: "0.5em" }}>
              Extension
            </div>
            <div className="extentionDivider"></div>
            <a
              href="https://chrome.google.com/webstore/detail/metamask/nkbihfbeogaeaoehlefnkodbefgpgknn"
              className="browserExtention"
            >
              <img
                src={braveIcon}
                alt="brave"
                className="singleConnectionIcon"
              />
              <div className="chromeName">Brave</div>
            </a>
            <a
              href="https://chrome.google.com/webstore/detail/metamask/nkbihfbeogaeaoehlefnkodbefgpgknn"
              className="browserExtention"
            >
              <img
                src={chromeIcon}
                alt="chrome"
                className="singleConnectionIcon"
              />
              <div className="chromeName">Chrome</div>
            </a>
            <a
              href="https://addons.mozilla.org/en-US/firefox/addon/ether-metamask/"
              className="browserExtention"
            >
              <img
                src={firefoxIcon}
                alt="firefox"
                className="singleConnectionIcon"
              />
              <div className="chromeName">Firefox</div>
            </a>
          </div>
          <div className="mobileAppContainer">
            <div style={{ textAlign: "center", marginTop: "0.5em" }}>
              Mobile App
            </div>
            <div className="extentionDivider"></div>
            <a
              href="https://metamask.app.link/skAH3BaF99"
              className="browserExtention"
            >
              <img
                src={appleIcon}
                alt="brave"
                className="singleConnectionIcon"
              />
              <div className="chromeName">iOS</div>
            </a>
            <a
              href="https://metamask.app.link/bxwkE8oF99"
              className="browserExtention"
            >
              <img
                src={playstoreIcon}
                alt="brave"
                className="singleConnectionIcon"
              />
              <div className="chromeName">Android</div>
            </a>
          </div>
        </div>
      </div>
      <div className="rightContentSection">
        <div className="reqNotFul extentions">
          <div className="browserExtentionContainer">
            <div style={{ textAlign: "center", marginTop: "0.5em" }}>
              What is MetaMask
            </div>
            <div className="extentionDivider"></div>
            <div>
              <span style={{ color: "var(--blueColor)" }}>
                Buy, store, send and swap tokens{" "}
              </span>
              <br />
              Available as a browser extension and as a mobile app, MetaMask
              equips you with a key vault, secure login and token wallet.
            </div>
            <div>
              <span style={{ color: "var(--blueColor)" }}>
                Explore blockchain apps
              </span>
              <br />
              MetaMask provides the simplest yet most secure way to connect to
              blockchain-based applications. You are always in control when
              interacting on the new decentralized web.
            </div>
            <div>
              <span style={{ color: "var(--redColor)" }}>Remarks</span>
              <br />- Please be aware that Proxima can only be played on a
              desktop browser. <br />- Mobile links will lead you to the app
              store or launch the already installed app. You can proceed wtih
              Metamasks build-in browser for Steps 2-4.
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StepOne;
