import React, { useEffect, useRef } from "react";
import { Helmet } from "react-helmet";
import { useNavigate } from 'react-router-dom';
import "./GamePage.css";

const GamePage = () => {
    const gamePageContainerRef = useRef(null);
    const navigate = useNavigate();

    useEffect(() => {
        const isMobileBrowser = () => {
            return /Mobi|Android/i.test(navigator.userAgent);
        };

        if (isMobileBrowser()) {
            alert("Mobile not supported.");
            setTimeout(() => {
                navigate(-1);
            });
        }
    }, [navigate]);

    const enterFullScreen = () => {
        if (gamePageContainerRef.current.requestFullscreen) {
            gamePageContainerRef.current.requestFullscreen();
        }
    };

    return (
        <div ref={gamePageContainerRef} className="game-page-container">
            <Helmet>
                <title>Game Page</title>
            </Helmet>

            <div className="game-container">
                <iframe
                    title="Proxima Game"
                    src="https://ckproxima.github.io/ProximaGameBuild/"
                    frameBorder="0"
                    allowFullScreen
                    className="game-iframe"
                />
            </div>
        </div>
    );
};

export default GamePage;