// packages
import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { HashLink } from "react-router-hash-link";

import { AnimationOnScroll } from "react-animation-on-scroll";

// pages and components

// styles
import "animate.css/animate.min.css";
import "./Starmap.css";

const Starmap = ({ currentMenu }) => {
  const { singleResource } = useParams();

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, [singleResource]);

  return (
    <>
      <div className="singleMenuPage">
        <div className="singleMenuPageContainer">
          <div className="singleMenuPage-currentSubMenu">
            <h3 className="currentSubMenu-title">{currentMenu.title}</h3>
            <img
              src={currentMenu.icon}
              alt="icon"
              className="currentSubMenu-icon"
            />
          </div>
          <div className="singleMenuPage-actionsContainer">
            <HashLink smooth to="#q1" className="actionsContainer-action">
              <span className="action-title">Go to: Q1/2024</span>
            </HashLink>
            <HashLink smooth to="#q2" className="actionsContainer-action">
              <span className="action-title">Go to: Q2/2024</span>
            </HashLink>
            <HashLink smooth to="#q3" className="actionsContainer-action">
              <span className="action-title">Go to: Q3/2024</span>
            </HashLink>
            <HashLink smooth to="#q4" className="actionsContainer-action">
              <span className="action-title">
                Go to: Q4/2024 
              </span>
                      </HashLink>
                      <HashLink smooth to="#qX2" className="actionsContainer-action">
                          <span className="action-title">Go to: Achievements of 2023 </span>
                      </HashLink>
            <HashLink smooth to="#qX1" className="actionsContainer-action">
              <span className="action-title">Go to: Achievements of 2022 </span>
            </HashLink>
          </div>
        </div>
      </div>

      <div className="singleMenuContent11">
        <div className="starmap">
          {/* 1st quarter */}
          <AnimationOnScroll
            animateIn="animate__fadeInLeftBig"
            animateOnce={true}
          >
            <div className="starmapContainer" id="q1">
              <div className="quarterIndication">
                <div className="singleQuarterIndication">
                  <h3 id="quarter1" className="quarter">
                    1st Quarter 2024
                  </h3>
                </div>
              </div>
              <div className="quarterContent">
                <div className="singleQuarterContent">
                  <h2 className="quarterContentTitle2">Game Launch</h2>
                  <p>
                    Mission status:{" "}
                    <span className="missionStatus outstanding">Coming Soon</span>
                  </p>
                </div>
                <div className="singleQuarterContent">
                  <h2 className="quarterContentTitle3">Marketing Drive</h2>
                  <p>
                    Mission status:{" "}
                    <span className="missionStatus outstanding">Coming Soon</span>
                  </p>
                </div>
                
              </div>
            </div>
          </AnimationOnScroll>

          {/* 2nd quarter */}
          <AnimationOnScroll
            animateIn="animate__fadeInLeftBig"
            animateOnce={true}
          >
            <div className="starmapContainer" id="q2">
              <div className="quarterContent">
                <div className="singleQuarterContent">
                  <h2 className="quarterContentTitle2">Expanding Proxima - New Chains</h2>
                  <p>
                    Mission status:{" "}
                    <span className="missionStatus outstanding">
                      Upcoming
                    </span>
                  </p>
                </div>
                <div className="singleQuarterContent">
                  <h2 className="quarterContentTitle4">NFT Airdrop & Sales</h2>
                  <p>
                    Mission status:{" "}
                    <span className="missionStatus outstanding">
                      Upcoming
                    </span>
                  </p>
                </div>
                <div className="singleQuarterContent">
                  <h2 className="quarterContentTitle3">Ongoing Marketing</h2>
                  <p>
                    Mission status:{" "}
                    <span className="missionStatus outstanding">
                      Ongoing
                    </span>
                  </p>
                </div>
              </div>
              <div className="quarterIndication">
                <div className="singleQuarterIndication">
                  <h3 id="quarter2" className="quarter">
                    2nd Quarter 2024
                  </h3>
                </div>
              </div>
            </div>
          </AnimationOnScroll>

          {/* 3rd quarter */}
          <AnimationOnScroll
            animateIn="animate__fadeInLeftBig"
            animateOnce={true}
          >
            <div className="starmapContainer" id="q3">
              <div className="quarterIndication">
                <div className="singleQuarterIndication">
                  <h3 id="quarter3" className="quarter">
                    3rd Quarter 2024
                  </h3>
                </div>
              </div>
              <div className="quarterContent">
                <div className="singleQuarterContent">
                  <h2 className="quarterContentTitle2">Bridge for new chains</h2>
                  <p>
                    Mission status:{" "}
                    <span className="missionStatus outstanding">
                      upcoming
                    </span>
                  </p>
                </div>
                <div className="singleQuarterContent">
                  <h2 className="quarterContentTitle4">
                    Proxima: Descension Sneak peek
                  </h2>
                  <p>
                    Mission status:{" "}
                    <span className="missionStatus outstanding">
                      upcoming
                    </span>
                  </p>
                </div>
              </div>
            </div>
          </AnimationOnScroll>

          {/* 4th quarter */}
          <AnimationOnScroll
            animateIn="animate__fadeInLeftBig"
            animateOnce={true}
          >
            <div className="starmapContainer" id="q4">
              <div className="quarterContent">
                <div className="singleQuarterContent">
                  <h2 className="quarterContentTitle3">Ongoing Q2/3 announcements and marketing</h2>
                  <p>
                    Mission status:{" "}
                    <span className="missionStatus outstanding">
                      ongoing
                    </span>
                  </p>
                              </div>
                              <div className="singleQuarterContent">
                                  <h2 className="quarterContentTitle3">Stay tuned for more announcements</h2>
                                  <p>
                                      Mission status:{" "}
                                      <span className="missionStatus outstanding">
                                          ongoing
                                      </span>
                                  </p>
                              </div>
              </div>
              <div className="quarterIndication">
                <div className="singleQuarterIndication">
                  <h3 id="quarter4" className="quarter">
                    4th Quarter 2024
                  </h3>
                </div>
              </div>
            </div>
          </AnimationOnScroll>

                  {/* Old roadmaps */}

                  {/* 2023 roadmap  */ }
                  <AnimationOnScroll
                      animateIn="animate__fadeInLeftBig"
                      animateOnce={true}
                  >
                      <div className="starmapContainer" id="qX2">
                          <div className="quarterIndication">
                              <div className="singleQuarterIndication">
                                  <h3 id="quarterX1" className="quarter">
                                      Achievements of 2023
                                  </h3>
                              </div>
                          </div>
                          <div className="quarterContent">
                              <div className="singleQuarterContent">
                                  <h2 className="quarterContentTitle5">Quarter 1</h2>
                                  <p>
                                      Prox Competition:{" "}
                                      <span className="missionStatus completed">completed</span>
                                  </p>
                                  <p>
                                      Staking Pool:{" "}
                                      <span className="missionStatus completed">completed</span>
                                  </p>
                                  <p>
                                      Closed Beta:{" "}
                                      <span className="missionStatus completed">completed</span>
                                  </p>
                                  <p>
                                      Open Beta:{" "}
                                      <span className="missionStatus completed">completed</span>
                                  </p>
                                  <p>
                                      Long Term Staking:{" "}
                                      <span className="missionStatus completed">completed</span>
                                  </p>
                              </div>
                              <div className="singleQuarterContent">
                                  <h2 className="quarterContentTitle5">Quarter 2</h2>
                                  <p>
                                      2023 Starmap Details:{" "}
                                      <span className="missionStatus completed">Moved to 2024</span>
                                  </p>
                                  <p>
                                      Whitepaper 2.0:{" "}
                                      <span className="missionStatus completed">Moved to 2024</span>
                                  </p>
                                  <p>
                                      Marketing Drive:{" "}
                                      <span className="missionStatus completed">Moved to 2024</span>
                                  </p>
                              </div>
                              <div className="singleQuarterContent">
                                  <h2 className="quarterContentTitle5">Quarter 3</h2>
                                  <p>
                                      Avatar Airdrop:{" "}
                                      <span className="missionStatus completed">Moved to 2024</span>
                                  </p>
                                  <p>
                                      Proxima: Descension NFT Mint:{" "}
                                      <span className="missionStatus completed">
                                          Ongoing
                                      </span>
                                  </p>
                              </div>
                              <div className="singleQuarterContent">
                                  <h2 className="quarterContentTitle5">Quarter 4</h2>
                                  <p>
                                      Game Launch!:{" "}
                                      <span className="missionStatus completed">Moved to 2024</span>
                                  </p>
                                  
                              </div>
                          </div>
                      </div>
                  </AnimationOnScroll>

                  {/* 2022 roadmap */}
          <AnimationOnScroll
            animateIn="animate__fadeInLeftBig"
            animateOnce={true}
          >
            <div className="starmapContainer" id="qX1">
              <div className="quarterIndication">
                <div className="singleQuarterIndication">
                  <h3 id="quarterX" className="quarter">
                    Achievements of 2022
                  </h3>
                </div>
              </div>
              <div className="quarterContent">
                <div className="singleQuarterContent">
                  <h2 className="quarterContentTitle5">Quarter 1</h2>
                  <p>
                    Project launch:{" "}
                    <span className="missionStatus completed">completed</span>
                  </p>
                  <p>
                    NFT reveal:{" "}
                    <span className="missionStatus completed">completed</span>
                  </p>
                  <p>
                    Whitepaper 1.0:{" "}
                    <span className="missionStatus completed">completed</span>
                  </p>
                  <p>
                    Twitter launch:{" "}
                    <span className="missionStatus completed">completed</span>
                  </p>
                  <p>
                    Discord launch:{" "}
                    <span className="missionStatus completed">completed</span>
                  </p>
                </div>
                <div className="singleQuarterContent">
                  <h2 className="quarterContentTitle5">Quarter 2</h2>
                  <p>
                    Website V1 launch:{" "}
                    <span className="missionStatus completed">completed</span>
                  </p>
                  <p>
                    Tellurian faction mint:{" "}
                    <span className="missionStatus completed">completed</span>
                  </p>
                  <p>
                    Machina faction mint:{" "}
                    <span className="missionStatus completed">completed</span>
                  </p>
                  <p>
                    Celestial faction mint:{" "}
                    <span className="missionStatus completed">completed</span>
                  </p>
                  <p>
                    Marketing phase 1:{" "}
                    <span className="missionStatus completed">completed</span>
                  </p>
                </div>
                <div className="singleQuarterContent">
                  <h2 className="quarterContentTitle5">Quarter 3</h2>
                  <p>
                    Gameplay demo:{" "}
                    <span className="missionStatus completed">completed</span>
                  </p>
                  <p>
                    Avatar airdrop:{" "}
                    <span className="missionStatus outstanding">
                      moved to Q3/2023
                    </span>
                  </p>
                  <p>
                    $PROX token airdrop:{" "}
                    <span className="missionStatus completed">completed</span>
                  </p>
                  <p>
                    Token presale/IDO:{" "}
                    <span className="missionStatus completed">completed</span>
                  </p>
                  <p>
                    Beta test launch:{" "}
                    <span className="missionStatus outstanding">
                      moved to Q1/2023
                    </span>
                  </p>
                  <p>
                    Marketing phase 2:{" "}
                    <span className="missionStatus completed">completed</span>
                  </p>
                </div>
                <div className="singleQuarterContent">
                  <h2 className="quarterContentTitle5">Quarter 4</h2>
                  <p>
                    Playable game demo:{" "}
                    <span className="missionStatus completed">completed</span>
                  </p>
                  <p>
                    Token general sale:{" "}
                    <span className="missionStatus completed">completed</span>
                  </p>
                  <p>
                    Token staking launch:{" "}
                    <span className="missionStatus completed">completed</span>
                  </p>
                  <p>
                    2023 Starmap:{" "}
                    <span className="missionStatus outstanding">
                      moved to Q2/2023
                    </span>
                  </p>
                  <p>
                    Whitepaper 2.0:{" "}
                    <span className="missionStatus outstanding">
                      moved to Q2/2023
                    </span>
                  </p>
                  <p>
                    Marketing phase 3:{" "}
                    <span className="missionStatus outstanding">
                      moved to Q2/2023
                    </span>
                  </p>
                  <p>
                    $PROX/Relay announcement:{" "}
                    <span className="missionStatus completed">completed</span>
                  </p>
                  <p>
                    $PROX/Relay giveaway:{" "}
                    <span className="missionStatus completed">completed</span>
                  </p>
                  <p>
                    PROXWORMS announcement:{" "}
                    <span className="missionStatus completed">completed</span>
                  </p>
                  <p>
                    PROXWORMS auctions:{" "}
                    <span className="missionStatus completed">completed</span>
                  </p>
                </div>
              </div>
            </div>
          </AnimationOnScroll>
        </div>
      </div>
    </>
  );
};

export default Starmap;
