export const traitData = [
  {
    faction: "tellurian",
    traits: [
      {
        trait: "Galaxies",
        subTrait: [
          { name: "Ursa", total: 445, percent: 13.35 },
          { name: "Andromeda", total: 419, percent: 12.57 },
          { name: "Tucana", total: 409, percent: 12.27 },
          { name: "Circinus", total: 364, percent: 10.92 },
          { name: "Eye of Sauron", total: 342, percent: 10.26 },
          { name: "Medusas Merger", total: 289, percent: 8.67 },
          { name: "Megallanic", total: 274, percent: 8.22 },
          { name: "Pegasus", total: 251, percent: 7.53 },
          { name: "Triangular", total: 197, percent: 5.91 },
          { name: "Serpens Caput", total: 167, percent: 5.01 },
          { name: "Darkside", total: 100, percent: 3.0 },
          { name: "Satoshis Lair", total: 76, percent: 2.28 },
        ],
      },
      {
        trait: "Star Fighter",
        subTrait: [
          { name: "TSF 10GM", total: 602, percent: 18.06 },
          { name: "TSF 10BE", total: 571, percent: 17.13 },
          { name: "TSF 10BS", total: 447, percent: 13.41 },
          { name: "TSF 10BR", total: 437, percent: 13.11 },
          { name: "TSF AU10B", total: 335, percent: 10.05 },
          { name: "TSF GHOST", total: 249, percent: 7.47 },
          { name: "KCS 166M", total: 217, percent: 6.51 },
          { name: "XMR ULS", total: 197, percent: 5.91 },
          { name: "MTV 10B", total: 168, percent: 5.04 },
          { name: "COC 3333", total: 56, percent: 1.68 },
          { name: "PNDA 10K", total: 36, percent: 1.08 },
          { name: "BTC SE21M", total: 18, percent: 0.54 },
        ],
      },
      {
        trait: "Fuselage",
        subTrait: [
          { name: "Core", total: 1652, percent: 49.56 },
          { name: "Carbon", total: 1047, percent: 31.41 },
          { name: "Blood", total: 465, percent: 13.95 },
          { name: "Lava", total: 169, percent: 5.07 },
        ],
      },
      {
        trait: "Canopy",
        subTrait: [
          { name: "Glass", total: 1156, percent: 34.68 },
          { name: "Emerald", total: 860, percent: 25.8 },
          { name: "Ruby", total: 675, percent: 20.25 },
          { name: "Gold", total: 404, percent: 12.12 },
          { name: "Obsidian", total: 147, percent: 4.41 },
          { name: "Diamond", total: 91, percent: 2.73 },
        ],
      },
      {
        trait: "Engine",
        subTrait: [
          { name: "Atlas V", total: 1342, percent: 40.26 },
          { name: "Delta IV", total: 986, percent: 29.58 },
          { name: "Saturn V", total: 634, percent: 19.02 },
          { name: "Falcon IX", total: 371, percent: 11.13 },
        ],
      },
      {
        trait: "After Burner",
        subTrait: [
          { name: "Downburst", total: 1173, percent: 35.19 },
          { name: "Cyclone", total: 974, percent: 29.22 },
          { name: "Tornado", total: 682, percent: 20.46 },
          { name: "Blizzard", total: 504, percent: 15.12 },
        ],
      },
      {
        trait: "Laser",
        subTrait: [
          { name: "Alpha", total: 851, percent: 25.53 },
          { name: "Crystal", total: 663, percent: 19.89 },
          { name: "Neon", total: 482, percent: 14.46 },
          { name: "Spartan", total: 336, percent: 10.08 },
          { name: "Plasma", total: 289, percent: 8.67 },
          { name: "Photon", total: 230, percent: 6.9 },
          { name: "Particle", total: 199, percent: 5.97 },
          { name: "Rebound", total: 121, percent: 3.63 },
          { name: "Zeus", total: 91, percent: 2.73 },
          { name: "Multivac", total: 71, percent: 2.13 },
        ],
      },
      {
        trait: "Missile",
        subTrait: [
          { name: "Ballistic", total: 876, percent: 26.28 },
          { name: "Homing", total: 598, percent: 17.94 },
          { name: "Scud", total: 526, percent: 15.78 },
          { name: "Tracer", total: 417, percent: 12.51 },
          { name: "Howitzer", total: 314, percent: 9.42 },
          { name: "Tomahawk", total: 255, percent: 7.65 },
          { name: "Satan", total: 214, percent: 6.42 },
          { name: "Minuteman", total: 133, percent: 3.99 },
        ],
      },
    ],
  },
  {
    faction: "machina",
    traits: [
      {
        trait: "Galaxies",
        subTrait: [
          { name: "Ursa", total: 485, percent: 14.55 },
          { name: "Andromeda", total: 464, percent: 13.92 },
          { name: "Tucana", total: 407, percent: 12.21 },
          { name: "Circinus", total: 363, percent: 10.89 },
          { name: "Eye of Sauron", total: 357, percent: 10.71 },
          { name: "Medusas Merger", total: 281, percent: 8.43 },
          { name: "Megallanic", total: 258, percent: 7.74 },
          { name: "Pegasus", total: 219, percent: 6.57 },
          { name: "Triangular", total: 193, percent: 5.79 },
          { name: "Serpens Caput", total: 134, percent: 4.02 },
          { name: "Darkside", total: 90, percent: 2.7 },
          { name: "Satoshis Lair", total: 82, percent: 2.46 },
        ],
      },
      {
        trait: "Star Fighter",
        subTrait: [
          { name: "MSF CUSN10B", total: 555, percent: 16.65 },
          { name: "MSF 10BL", total: 523, percent: 15.69 },
          { name: "MSF 10BP", total: 458, percent: 13.74 },
          { name: "MSF GHOST", total: 394, percent: 11.82 },
          { name: "PROX 10B", total: 328, percent: 9.84 },
          { name: "MTVS ULS", total: 259, percent: 7.77 },
          { name: "LUNA 751M", total: 241, percent: 7.23 },
          { name: "BNB 165M", total: 206, percent: 6.18 },
          { name: "MTV 10B", total: 144, percent: 4.32 },
          { name: "LB 10K", total: 105, percent: 3.15 },
          { name: "MW 3333", total: 104, percent: 3.12 },
          { name: "BTC SE21M", total: 16, percent: 0.48 },
        ],
      },
      {
        trait: "Fuselage",
        subTrait: [
          { name: "Steel", total: 1699, percent: 50.98 },
          { name: "Aluminium", total: 959, percent: 28.77 },
          { name: "Kevlar", total: 497, percent: 14.91 },
          { name: "Titanium", total: 178, percent: 5.34 },
        ],
      },
      {
        trait: "Canopy",
        subTrait: [
          { name: "Polycarbonate", total: 1191, percent: 35.73 },
          { name: "Silicon", total: 805, percent: 24.15 },
          { name: "Wurtzite", total: 643, percent: 19.29 },
          { name: "Lonsdalite", total: 417, percent: 12.51 },
          { name: "Dyneema", total: 166, percent: 4.98 },
          { name: "Graphene", total: 111, percent: 3.33 },
        ],
      },
      {
        trait: "Engine",
        subTrait: [
          { name: "Impulse", total: 1319, percent: 39.57 },
          { name: "Bistromathic", total: 1031, percent: 30.93 },
          { name: "Holtzman", total: 634, percent: 19.02 },
          { name: "Tylium", total: 349, percent: 10.47 },
        ],
      },
      {
        trait: "After Burner",
        subTrait: [
          { name: "Rumble", total: 1172, percent: 35.16 },
          { name: "Drumfire", total: 1029, percent: 30.87 },
          { name: "Cannondale", total: 648, percent: 19.44 },
          { name: "Uproad", total: 484, percent: 14.52 },
        ],
      },
      {
        trait: "Laser",
        subTrait: [
          { name: "Blaster", total: 823, percent: 24.69 },
          { name: "Jolt", total: 647, percent: 19.41 },
          { name: "Sonic", total: 481, percent: 14.43 },
          { name: "Tag", total: 353, percent: 10.59 },
          { name: "Arc", total: 257, percent: 7.71 },
          { name: "Identity", total: 216, percent: 6.48 },
          { name: "Hawksman", total: 214, percent: 6.42 },
          { name: "Lawgiver", total: 151, percent: 4.53 },
          { name: "Saber", total: 112, percent: 3.36 },
          { name: "Proxima", total: 79, percent: 2.37 },
        ],
      },
      {
        trait: "Missile",
        subTrait: [
          { name: "Blazer", total: 823, percent: 24.69 },
          { name: "Tractor", total: 647, percent: 19.41 },
          { name: "Bruiser", total: 481, percent: 14.43 },
          { name: "Darkforce", total: 353, percent: 10.59 },
          { name: "Armageddon", total: 257, percent: 7.71 },
          { name: "Doomsday", total: 216, percent: 6.48 },
          { name: "Manhattan", total: 214, percent: 6.42 },
          { name: "WMD", total: 151, percent: 4.53 },
        ],
      },
    ],
  },
  {
    faction: "celestial",
    traits: [
      {
        trait: "Galaxies",
        subTrait: [
          { name: "Ursa", total: 493, percent: 14.79 },
          { name: "Andromeda", total: 479, percent: 14.37 },
          { name: "Tucana", total: 409, percent: 12.27 },
          { name: "Circinus", total: 385, percent: 11.55 },
          { name: "Eye of Sauron", total: 320, percent: 9.6 },
          { name: "Medusas Merger", total: 276, percent: 8.28 },
          { name: "Megallanic", total: 248, percent: 7.44 },
          { name: "Pegasus", total: 199, percent: 5.97 },
          { name: "Triangular", total: 193, percent: 5.79 },
          { name: "Serpens Caput", total: 155, percent: 4.65 },
          { name: "Darkside", total: 115, percent: 3.45 },
          { name: "Satoshis Lair", total: 61, percent: 1.83 },
        ],
      },
      {
        trait: "Star Fighter",
        subTrait: [
          { name: "CSF 10HP", total: 563, percent: 16.89 },
          { name: "CSF 10MG", total: 526, percent: 15.78 },
          { name: "CPROX 10B", total: 414, percent: 12.42 },
          { name: "MTV GHOST", total: 391, percent: 11.73 },
          { name: "CSF GURU", total: 339, percent: 10.17 },
          { name: "ZIL 21B", total: 276, percent: 8.28 },
          { name: "SOL 508M", total: 247, percent: 7.41 },
          { name: "CRO 30M", total: 194, percent: 5.82 },
          { name: "DOGE 1", total: 164, percent: 4.92 },
          { name: "CSF KAL PL", total: 103, percent: 3.09 },
          { name: "MTVP 10K", total: 92, percent: 2.76 },
          { name: "BTC 21C", total: 24, percent: 0.72 },
        ],
      },
      {
        trait: "Fuselage",
        subTrait: [
          { name: "Meteorite", total: 1656, percent: 49.68 },
          { name: "Nove", total: 991, percent: 29.73 },
          { name: "Xenomorph", total: 538, percent: 16.14 },
          { name: "Flare", total: 148, percent: 4.44 },
        ],
      },
      {
        trait: "Canopy",
        subTrait: [
          { name: "Bone", total: 1167, percent: 35.01 },
          { name: "Plasma", total: 829, percent: 24.87 },
          { name: "Acetamide", total: 681, percent: 20.43 },
          { name: "Chondrite", total: 372, percent: 11.16 },
          { name: "Isocyanate", total: 189, percent: 5.67 },
          { name: "Fullerite", total: 95, percent: 2.85 },
        ],
      },
      {
        trait: "Engine",
        subTrait: [
          { name: "Ghost", total: 1359, percent: 40.77 },
          { name: "Narada", total: 995, percent: 29.85 },
          { name: "Nostromo", total: 636, percent: 19.08 },
          { name: "Oumuamua", total: 343, percent: 10.29 },
        ],
      },
      {
        trait: "After Burner",
        subTrait: [
          { name: "Flare", total: 1181, percent: 35.43 },
          { name: "Ghost", total: 1021, percent: 30.63 },
          { name: "Eruption", total: 647, percent: 19.41 },
          { name: "Carrington", total: 484, percent: 14.52 },
        ],
      },
      {
        trait: "Laser",
        subTrait: [
          { name: "Cyber", total: 858, percent: 25.74 },
          { name: "Shockwave", total: 672, percent: 20.16 },
          { name: "Gristle", total: 477, percent: 14.31 },
          { name: "Berserker", total: 302, percent: 9.06 },
          { name: "Auto9", total: 273, percent: 8.19 },
          { name: "Mjolnir", total: 242, percent: 7.26 },
          { name: "Gundam", total: 208, percent: 6.24 },
          { name: "Ithica", total: 128, percent: 3.84 },
          { name: "Neuromancer", total: 111, percent: 3.33 },
          { name: "Ghost", total: 62, percent: 1.86 },
        ],
      },
      {
        trait: "Missile",
        subTrait: [
          { name: "Shadow", total: 852, percent: 25.56 },
          { name: "Changeling", total: 627, percent: 18.81 },
          { name: "Genesis", total: 468, percent: 14.04 },
          { name: "Anubis", total: 440, percent: 13.2 },
          { name: "Ghost", total: 350, percent: 10.5 },
          { name: "Omega", total: 270, percent: 8.1 },
          { name: "Liberty", total: 186, percent: 5.58 },
          { name: "Starkilller", total: 140, percent: 4.2 },
        ],
      },
    ],
  },
];
