import React, { useState, useEffect } from 'react';
import './supply.css';
import { Pie } from 'react-chartjs-2';
import 'chart.js/auto';

function formatNumber(num) {
    return new Intl.NumberFormat('en-US', {
        maximumFractionDigits: 0  // Remove decimals
    }).format(num);
}

function SupplyData() {
    const [supplyData, setSupplyData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        console.log('Fetching data...');
        fetch('https://singulayerty.com/api/PROX/supply')
            .then(response => {
                if (response.ok) {
                    return response.json();
                }
                throw new Error('Network response was not ok.');
            })
            .then(data => {
                console.log('Data fetched:', data);
                setSupplyData(data);
                setLoading(false);
            })
            .catch(error => {
                console.error('Fetch error:', error);
                setError(error.message);
                setLoading(false);
            });
    }, []);

    if (loading) return <div>Loading...</div>;
    if (error) return <div>Error: {error}</div>;


    const entries = [
        { label: 'PROX Team Share', value: supplyData?.total_team },
        { label: 'PROX Community', value: supplyData?.total_community },
        { label: 'PROX Foundation', value: supplyData?.total_foundation },
        { label: 'PROX Game Rewards', value: supplyData?.total_rewards },
        { label: 'PROX EcoDev', value: supplyData?.total_ecodev },
        { label: 'PROX Game Pay', value: supplyData?.total_game_pay },
        { label: 'PROX Armory Pay', value: supplyData?.total_armory_pay },
        { label: 'PROX Rental Pay', value: supplyData?.total_rental_pay },
        { label: 'PROX in Pools', value: supplyData?.total_in_pools },
        { label: 'PROX in All Other Wallets', value: supplyData?.total_in_all_other_wallets },
        { label: 'PROX in Pool Distributors', value: supplyData?.total_in_pool_distributors },
        { label: 'PROX Burned', value: supplyData?.total_burn }
    ];

    const colorMapping = {
        'PROX Team Share': '#293b50', // Dark blue
        'PROX Community': '#345675', // Moderate blue
        'PROX Foundation': '#1e2740', // Very dark blue
        'PROX Game Rewards': '#456990', // Lighter blue
        'PROX EcoDev': '#2d3e55', // Slightly lighter than #293b50
        'PROX Game Pay': '#3c5078', // A muted blue
        'PROX Armory Pay': '#006400', // Dark green
        'PROX Rental Pay': '#228B22', // Forest green
        'PROX in Pools': '#2E8B57', // Medium sea green
        'PROX in All Other Wallets': '#3CB371', // Medium spring green
        'PROX in Pool Distributors': '#66CDAA', // Aquamarine
        'PROX Burned': '#2B2B2B' // Dark grey to represent burned tokens
    };

    // Sort entries based on the value
    entries.sort((a, b) => b.value - a.value);
    entries.reverse();

    const labels = entries.map(entry => entry.label);
    const data = entries.map(entry => entry.value);

    // Use the color mapping to set the background colors
    const backgroundColor = labels.map(label => colorMapping[label] || '#000000'); // Default to black if no match
    const hoverBackgroundColor = labels.map(label => colorMapping[label] || '#333333'); // Slightly lighter for hover

    const pieData = {
        labels,
        datasets: [{
            label: 'Supply Data',
            data,
            backgroundColor: backgroundColor,
            hoverBackgroundColor: hoverBackgroundColor,
            borderColor: '#8B0000',  // Deep red for the borders
            borderWidth: 2  // Thickness of the border
        }]
    };

    const options = {
        responsive: true,
        maintainAspectRatio: true, // Set to false to fully control via CSS
        plugins: {
            legend: {
                display: false // Ensure the legend is turned off
            }
        },
        tooltips: {
            mode: 'index',
            intersect: false,
            bodyFontSize: 12
        }
    };


    return (
        <div className="supplycontainer">
            <div className="chart-container">
                <Pie data={pieData} options={options} />
            </div>
            <h1>PROX Token Supply Data</h1>
            {supplyData ? (
                <div>
                    <ul>
                        <li className="supplyhighlight"><strong>Circulating Supply:</strong> {formatNumber(supplyData.circulating_supply)}</li>
                        <li>Total in PROX contract: {formatNumber(10000000000)}</li>
                        <li><strong>Actual Supply:</strong> {formatNumber(supplyData.total_supply)}</li>
                        <li><strong>Burn Wallet:</strong> {formatNumber(supplyData.total_burn)}</li>
                        <li><strong>Team Wallet:</strong> {formatNumber(supplyData.total_team)}</li>
                        
                        <li><strong>Community Wallet:</strong> {formatNumber(supplyData.total_community)}</li>
                        <li><strong>Foundation Wallet:</strong> {formatNumber(supplyData.total_foundation)}</li>
                        <li><strong>Rewards Wallet:</strong> {formatNumber(supplyData.total_rewards)}</li>
                        <li><strong>Ecosystem Development Wallet:</strong> {formatNumber(supplyData.total_ecodev)}</li>
                        <li><strong>Game Pay wallet:</strong> {formatNumber(supplyData.total_game_pay)}</li>
                        <li><strong>Armory Wallet:</strong> {formatNumber(supplyData.total_armory_pay)}</li>
                        <li><strong>Rental Wallet:</strong> {formatNumber(supplyData.total_rental_pay)}</li>
                        <li><strong>Total in all Team Wallets:</strong> {formatNumber(supplyData.team_wallet_total)}</li>
                        <li><strong>Total in all Pools:</strong> {formatNumber(supplyData.total_in_pools)}</li>
                        <li><strong>Total in Pool Distributors:</strong> {formatNumber(supplyData.total_in_pool_distributors)}</li>
                        <li><strong>Total in All Other Wallets:</strong> {formatNumber(supplyData.total_in_all_other_wallets)}</li>
                        <li><strong>Total of All Tokens Added:</strong> {formatNumber(supplyData.total_of_all_tokens_added)}</li>
                    </ul>

                </div>
            ) : (
                <div>No data found</div>
            )}
        </div>
    );
}

export default SupplyData;