// packages
import React from "react";
import { useNavigate } from "react-router-dom";

// pages and components

// styles
import "./Menu.css";

const Menu = ({ content, special }) => {
  const navigate = useNavigate();

  if (special) {
    return (
      <div className="mainMenuPage">
        <div className="mainMenuPage-subMenu">
          <a href={content.src}>
            <h3 className="subMenu-title">{content.title}</h3>
            <img
              src={content.icon}
              alt="icon"
              className={`subMenu-icon ${special ? special : ""}`}
            />
          </a>
        </div>
      </div>
    );
  }

  return (
    <div className="mainMenuPage">
      <div
        className="mainMenuPage-subMenu"
        onClick={() => navigate(content.src)}
      >
        <h3 className="subMenu-title">{content.title}</h3>
        <img
          src={content.icon}
          alt="icon"
          className={`subMenu-icon ${special ? special : ""}`}
        />
      </div>
    </div>
  );
};

export default Menu;
