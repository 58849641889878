// packages
import React, { useEffect } from "react";
import { useParams } from "react-router-dom";

// pages and components
import { extractContent } from "../../components/functions/extractContent";
import { menuItemData } from "../../data/menuItemData";
import Contracts from "./Contracts";
import Starmap from "./Starmap";
import Team from "./Team";
import Whitepaper from "./Whitepaper";

// styles
import "./SingleResource.css";

const SingleResource = () => {
  const { singleResource } = useParams();

  const [currentMenu] = extractContent(menuItemData, 1, "Resources").filter(
    (content) => content.search === singleResource
  );

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, [singleResource]);

  return (
    <>
      {singleResource === "starmap" ? (
        <Starmap currentMenu={currentMenu} />
      ) : singleResource === "contracts" ? (
        <Contracts currentMenu={currentMenu} />
      ) : singleResource === "team" ? (
        <Team currentMenu={currentMenu} />
      ) : singleResource === "whitepaper" ? (
        <Whitepaper currentMenu={currentMenu} />
      ) : (
        <div></div>
      )}
    </>
  );
};

export default SingleResource;
