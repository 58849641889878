import React from "react";
import { useNavigate } from "react-router-dom";
import headLogo from "../../assets/headLogo.png";
import { menuItemData } from "../../data/menuItemData";
import MenuItems from "./MenuItems";
import "./Navbar.css";

const NavbarLeft = ({ open }) => {
  const navigate = useNavigate();
  const depthLevel = 0;
  return (
    <>
      <img
        className="logoHead"
        src={headLogo}
        onClick={() => navigate("/")}
        alt="Proxima Head"
      />
      <nav className={`nav left ${open ? "active" : ""}`}>
        <ul className={`menus ${open ? "active" : ""}`}>
          {menuItemData.map((menu, index) => {
            return (
              <MenuItems
                items={menu}
                depthLevel={depthLevel}
                key={index}
                menuOpen={open}
              />
            );
          })}
        </ul>
      </nav>
    </>
  );
};

export default NavbarLeft;
