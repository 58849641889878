// packages
import React, { useEffect } from "react";
import { useParams } from "react-router-dom";

// pages and components
import { extractContent } from "../../components/functions/extractContent";
import Menu from "../../components/Menu";
import { menuItemData } from "../../data/menuItemData";

// styles
import "./Shop.css";

const Shop = () => {
  const contents = extractContent(menuItemData, 1, "Shop");
  const { singleShop } = useParams();

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, [singleShop]);

  return (
    <div className="shop-main">
      {contents.map((content, index) => {
        return <Menu content={content} key={index} />;
      })}
    </div>
  );
};

export default Shop;
