// packages
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

// pages and components
import WhitepaperPartOne from "./whitepaperContent/WhitepaperPartOne";
import WhitepaperPartThree from "./whitepaperContent/WhitepaperPartThree";
import WhitepaperPartTwo from "./whitepaperContent/WhitepaperPartTwo";

// styles
import "./Whitepaper.css";

const Whitepaper = ({ currentMenu }) => {
  const { singleResource } = useParams();
  const [part, setPart] = useState(0);

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, [singleResource]);

  return (
    <>
      <div className="singleMenuPage">
        <div className="singleMenuPageContainer">
          <div className="singleMenuPage-currentSubMenu">
            <h3 className="currentSubMenu-title">{currentMenu.title}</h3>
            <img
              src={currentMenu.icon}
              alt="icon"
              className="currentSubMenu-icon"
            />
          </div>
          <div className="singleMenuPage-actionsContainer">
            <div
              className={`actionsContainer-action ${part === 1 && "active"}`}
              onClick={() => setPart((current) => (current === 1 ? 0 : 1))}
            >
              <p className="action-title">
                Show Part I: Enter the World of Proxima
              </p>
            </div>
            <div
              className={`actionsContainer-action ${part === 2 && "active"}`}
              onClick={() => setPart((current) => (current === 2 ? 0 : 2))}
            >
              <p className="action-title">
                Show Part II: User engagement and NFTs
              </p>
            </div>
            <div
              className={`actionsContainer-action ${part === 3 && "active"}`}
              onClick={() => setPart((current) => (current === 3 ? 0 : 3))}
            >
              <p className="action-title">
                Show Part III: $PROX - Proxima Game Credits
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="singleMenuContent">
        <div className="whitepaper">
          {part === 1 ? (
            <WhitepaperPartOne />
          ) : part === 2 ? (
            <WhitepaperPartTwo />
          ) : part === 3 ? (
            <WhitepaperPartThree />
          ) : (
            <div style={{ display: "flex", justifyContent: "center" }}>
              Select Whitepaper to be shown.
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default Whitepaper;
