// packages
import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import { eagerLoadTwitterLibrary, Timeline } from "react-twitter-widgets";

// pages and components
import { extractContent } from "../../components/functions/extractContent";
import { menuItemData } from "../../data/menuItemData";

// styles
import "./Home.css";

const Home = () => {
  eagerLoadTwitterLibrary();

  // content data
  const mainMenu = menuItemData;
  const [tellurian, machina, celestial] = extractContent(
    menuItemData,
    1,
    "Factions"
  );

  // jump back to top after click
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);

  return (
    <>
      <Helmet>
      </Helmet>
          <div className="home-container">
              {/* <button className="launch-game-button">Coming Soon</button> */}
        <div id="htam" className="home-primary">
          <div className="home-primary-text">
            <h2>About Proxima</h2>
            <p>
              The game features 3 factions:
              <Link to="/factions/tellurian">
                <span> The Tellurian </span>
              </Link>
              ,
              <Link to="/factions/machina">
                <span> The Machina </span>
              </Link>
              and
              <Link to="/factions/celestial">
                <span> The Celestial</span>
              </Link>
              .
            </p>
            <p>
              Each faction has have a maximum NFT mint of 3333 craft. All those
              with a <span>unique combination</span> of traits. They not only
              add rarity to the respective NFT but also will translate directly
              to <span>in-game attributes</span> to enhance your quest to defeat
              the Satoshi Empire.
            </p>
            <p>Total of 9999 Proxima NFTS</p>
          </div>
          <div className="home-primary-menus">
            {mainMenu.map((menu, index) => {
              return (
                <Link className="home-primary-menu" to={menu.src} key={index}>
                  {menu.title}
                </Link>
              );
            })}
          </div>
        </div>
        <div className="home-divider">
          <div className="tellurian-divider">
            <img className="divider-img" src={tellurian.ship} alt="ship" />
          </div>
          <div className="machina-divider">
            <img className="divider-img" src={machina.ship} alt="ship" />
          </div>
          <div className="celestial-divider">
            <img className="divider-img" src={celestial.ship} alt="ship" />
          </div>
        </div>
        <div className="home-secondary">
          {/* Twitter section */}
          <div className="home-secondary-container-group">
            <div id="twitterTimeline" className="home-secondary-container">
              <Timeline
                dataSource={{
                  sourceType: "profile",
                  screenName: "ProximaTheGame",
                }}
                options={{
                  height: "400rem",
                  theme: "dark",
                  tweetLimit: 3,
                  chrome: "noborders noheader noscrollbar",
                }}
              />
            </div>
          </div>

          {/* Game description section */}
          <div className="home-secondary-container-group">
            <div className="home-secondary-container">
              <h2>Retro Meets The Blockchain</h2>
              <p>
                Proxima is inspired by our favourite old school arcade games
                with a twist.
              </p>
              <p>
                We have combined <span>blockchain technology</span> to bring to
                you this immersive experience, whilst earning a real world
                income from our <span>play to earn</span> structure.
              </p>
            </div>
            <div className="home-secondary-container">
              <h2>Community</h2>
              <p>
                Our motivation for this project, has always been to{" "}
                <span>reward the player</span> and promote the Multivac eco
                system.
              </p>
              <p>
                Join the <span>Proxima family</span> in every way possible to
                create value and utility in our NFTS and P2E game.
              </p>
              <p>
                On top of this we want to bring up other projects along the
                ride. So far we have secured some amazing projects in the space
                of hours from project launch.
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Home;
