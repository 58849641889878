// packages
import React from "react";
import { Link, useNavigate } from "react-router-dom";

// pages and components

// styles
import "./GenericCard.css";

const GenericCard = ({ content, linkType, navLinkRelevant }) => {
  const navigate = useNavigate();

  if (navLinkRelevant === true) {
    if (content.type === "page") {
      return (
        <Link className="actionsContainer-action" to={content.src}>
          <img src={content.icon} className="action-img" alt="icon" />
          <p className="action-title"> {content.title}</p>
        </Link>
      );
    }
    if (content.type === "externalURL") {
      return (
        <a className="actionsContainer-action" href={content.src}>
          <img src={content.icon} className="action-img" alt="icon" />
          <p className="action-title"> {content.title}</p>
        </a>
      );
    }
  }

  if (linkType === "external") {
    return (
      <a className="actionsContainer-action" href={content.src}>
        <img src={content.icon} className="action-img" alt="icon" />
        <p className="action-title"> {content.title}</p>
      </a>
    );
  }

  return (
    <div
      className="actionsContainer-action"
      onClick={() => navigate(content.src)}
    >
      <img src={content.icon} className="action-img" alt="icon" />
      <p className="action-title"> {content.title}</p>
    </div>
  );
};

export default GenericCard;
